<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 23">
        <path clip-rule="evenodd" d="M1.693 5.571a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2h-14a2 2 0 01-2-2v-14z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.693 1.571v4M6.693 1.571v4M1.693 9.571h18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {};
</script>
