<template>
    <div class="mt-4 position-relative" :class="{'readonly': fieldLocked, 'is-invalid': errorMessage}" data-form-item>
        <p class="field-label">{{ field.label }}</p>
        <div class="data-source-field">
            <template v-for="(list, key) in lists" :key="key">
                <VSelect
                    class="mb-3"
                    :placeholder="list.field_label"
                    :options="list.values"
                    v-model="selectedValues[key]"
                    :loading="loadings[key]"
                    :disabled="isLoading || fieldLocked"
                    :filterable="false"
                    @update:model-value="clean(Number(key) + 1)"
                    @search="(search, loading)=>debounceFetch(search, loading, Number(key), getParentSlug(Number(key)))"
                    @open="onOpenSelect(key)"
                />
            </template>
            <div class="field-placeholder mb-4" v-if="isLoading">
                <span class="inline-spinner-loader"></span>
            </div>
            <input-text v-if="showOtherField" v-model="otherValue" :error="!!errorMessage"
                        placeholder="Précisez..."></input-text>
        </div>
        <div class="invalid-tooltip bottom">
            {{ errorMessage }}
        </div>
        <input-helper v-if="field.helper_text" v-html="field.helper_text" />
    </div>
</template>

<script setup>
import { computed, reactive, ref, toRefs, watch, watchEffect } from 'vue';
import InputHelper from '../input-helper.vue';
import InputText from './base/input-text.vue';
import Axios from '../../axios';
import global from '../../composables/global';
import VSelect from 'vue-select';
import { lastValueFrom } from 'rxjs';
import debounce from 'lodash/debounce';
import { lowerCase, forEach, map } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import form from '../../composables/form';

const OTHER_SLUG = '__other__';
const props = defineProps({
    field: Object,
    errorMessage: String,
    modelValue: String,
});
const emit = defineEmits(['update:modelValue']);
const { state: { meeting } } = global;
const { getLastValue } = form;
const { field, modelValue } = toRefs(props);
let fieldLocked = field.value.locked_when_prefilled;
let allowOtherOption = field.value.allow_other_option;

const lists = reactive({});
const selectedValues = reactive({});
const loadings = reactive({});
const isLoading = ref(false);
const otherValue = ref('');

if (isEmpty(modelValue.value) && fieldLocked) {
    fieldLocked = false;
}

watch([selectedValues, otherValue], ([val]) => emit('update:modelValue', formatEmittedValues(val)));

async function fetch(search, loading, level = 1, parentSlug) {
    if (loading) {
        loadings[level] = true;
    } else {
        isLoading.value = true;
    }
    const response = await lastValueFrom(Axios.request({
        method: 'get',
        url: `/meeting/${meeting.id}/field/${field.value['id']}/${level}` + (parentSlug ? `/${parentSlug}` : ''),
        params: {
            ...(search && { q: lowerCase(search) }),
        },
    }));
    lists[level] = formatList(response, level);
    if (loading) {
        loadings[level] = false;
    } else {
        isLoading.value = false;
    }
}

const debounceFetch = debounce(fetch, 400);

function getLengths() {
    return {
        listsLength: Object.keys(lists).length,
        valuesLength: Object.keys(selectedValues).length,
    };
}

function clean(level) {
    const { listsLength, valuesLength } = getLengths();
    for (let index = level; index <= Math.max(listsLength, valuesLength); index++) {
        delete selectedValues[index];
        delete lists[index];
    }
    otherValue.value = '';
}

function formatEmittedValues(values) {
    const result = {};
    forEach(values, (val, key) => {
        if (val) {
            result[lists[key]['field_label']] = val;
        }
    });

    if (showOtherField.value) {
        result[OTHER_SLUG] = otherValue.value;
    }

    return isEmpty(result) ? null : JSON.stringify(result);
}

function isJsonValid(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function fillLocalValuesFromProps() {
    if (!modelValue.value || !isJsonValid(modelValue.value)) return;
    const values = JSON.parse(modelValue.value);
    forEach(values, (val, key) => {
        if (key === OTHER_SLUG) {
            otherValue.value = val;
        } else {
            selectedValues[val['level']] = val;
            lists[val['level']] = {
                field_label: key,
                values: [val],
                options_incomplete: true,
            };
        }
    });
}

function onOpenSelect(key) {
    const _key = Number(key);
    if (lists[_key]['options_incomplete']) {
        let parentSlug = null;
        if (_key > 1) {
            parentSlug = selectedValues[_key - 1]['slug'];
        }
        fetch(null, true, _key, parentSlug);
    }
}

function formatList(list, level) {
    list.values = map(list.values, values => {
        return { ...values, level };
    });
    if (allowOtherOption) {
        list.values.push({
            has_child: false,
            label: 'Autre',
            level: Number(level),
            slug: OTHER_SLUG,
        });
    }
    return list;
}

fillLocalValuesFromProps();

watchEffect(() => {
    const { listsLength, valuesLength } = getLengths();
    if (listsLength === 0) {
        fetch();
        return;
    }
    if (selectedValues[valuesLength]?.['has_child'] && listsLength === valuesLength) {
        fetch(null, null, valuesLength + 1, selectedValues[valuesLength]['slug']);
    }
});

const showOtherField = computed(() => {
    const lastValue = getLastValue(selectedValues);

    return lastValue?.slug === OTHER_SLUG;
});

const getParentSlug = (level) => {
    if (level === 1) return null;

    return selectedValues[level - 1]['slug'];
};

</script>
