<template>
    <router-view/>
    <popin-gdpr-form></popin-gdpr-form>
    <popin-gdpr></popin-gdpr>
    <popin-alert></popin-alert>
    <popin-summary></popin-summary>
</template>

<script>
import global from '../composables/global';

export default {
    props: ['meeting', 'scheduled-meeting', 'area', 'config', 'gkey', 'hub', 'limited-time-sequences'],
    setup(props) {
        const { initApp } = global;
        if (window.base_url === 'http://localhost:3000/'
            || window.base_url === 'https://preprod.app.apenday.com/') {
            console.log(props);
        }
        initApp(props);
    },
};
</script>
