import { SetupCalendar, DatePicker } from 'v-calendar';

export default function Components(app) {
    app = app.use(SetupCalendar, {
        touch: 'none',
        masks: {
            title: 'MMMM YYYY',
            weekdays: 'W',
            navMonths: 'MMM',
            input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
            dayPopover: 'WWW, MMM D, YYYY',
            data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
        },
        theme: {
            color: 'nova',
            container: {
                light:
                    'vc-text-gray-900 vc-bg-white',
                dark:
                    'vc-text-gray-200 vc-bg-gray-900',
            },
            title: {
                light: 'vc-text-lg vc-text-gray-800 vc-font-semibold hover:vc-opacity-75 vc-nova-title',
                dark: 'vc-text-lg vc-text-gray-100 vc-font-semibold hover:vc-opacity-75 vc-nova-title',
            },
            weekdays: {
                light: 'vc-text-sm vc-nova-weekday',
                dark: 'vc-text-sm vc-text-{color}-200',
            },
            dayContent:
                'vc-font-medium vc-text-sm vc-cursor-pointer vc-rounded-full',
            navCell: {
                light:
                    'vc-w-12 vc-font-semibold vc-cursor-pointer vc-text-center vc-leading-snug vc-py-1 vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-gray-900',
                dark:
                    'vc-w-12 vc-font-semibold vc-cursor-pointer vc-text-center vc-leading-snug vc-py-1 vc-rounded vc-border-2 vc-border-transparent hover:vc-bg-gray-200',
            },
            datePickerInput:
                'vc-appearance-none vc-text-base vc-text-gray-800 vc-bg-white vc-border vc-border-gray-400 vc-rounded vc-w-full vc-py-2 vc-px-3 vc-leading-tight focus:vc-outline-none',
            datePickerInputDrag:
                'vc-appearance-none vc-text-base vc-text-gray-500 vc-bg-white vc-border vc-border-gray-400 vc-rounded vc-w-full vc-py-2 vc-px-3 vc-leading-tight focus:vc-outline-none',
        },
    });

    return app.component('vc-date-picker', DatePicker);
}
