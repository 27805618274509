<template>
    <div class="container">
        <div class="app-content">
            <section class="cant-cancel-panel">
                <template v-if="actionInProgress === 'update'">
                    <h3>Modification impossible</h3>
                    <p class="mt-3">Le délai avant modification est dépassé, vous ne pouvez plus modifier cette
                        réservation.</p>
                </template>
                <template v-else>
                    <h3>Annulation impossible</h3>
                    <p class="mt-3">Le délai avant annulation est dépassé, vous ne pouvez plus annuler cette
                        réservation.</p>
                </template>

                <section class="my-5" v-if="isUpdatable || isCancellable">
                    <p class="mb-2 font-4">Modifier votre rendez-vous</p>
                    <div class="row">
                        <div class="col-auto" v-if="isUpdatable">
                            <a :href="getMeetingUrl('update')" class="btn btn-primary">Déplacer</a>
                        </div>
                        <div class="col-auto" v-if="isCancellable">
                            <a :href="getMeetingUrl('cancel')" class="btn btn-primary">Annuler</a>
                        </div>
                    </div>
                </section>

                <section class="mb-3" v-if="isUpdatable && meeting.update_policy_shown_in_confirm_page"
                         v-html="meeting.update_policy"></section>
                <section class=""
                         v-if="isCancellable && meeting.cancellation_policy_shown_in_confirm_page"
                         v-html="meeting.cancellation_policy"></section>
            </section>
        </div>
    </div>
</template>

<script>
import global from '../composables/global';

export default {
    setup() {
        const { state: { meeting, actionInProgress }, isUpdatable, isCancellable, getMeetingUrl } = global;

        return {
            meeting,
            actionInProgress,
            isUpdatable,
            isCancellable,
            getMeetingUrl,
        };
    },
};
</script>
