<template>
    <transition name="popin">
        <popin v-if="gdprFormIsOpen" @bgClick="onCancel">
            <form @submit="onSubmit">
                <p class="typo-1 text-center mb-7">Formulaire</p>
                <div class="font-4 mb-5 popin__message popin__message--rgpd">

                    <div v-if="successMsg">
                        <p v-for="message of successMsg" v-html="message"></p>
                        <ul v-if="pending_scheduled_meeting_dates">
                            <li v-for="date of pending_scheduled_meeting_dates">{{ getFormatedDate(date) }}</li>
                        </ul>
                    </div>
                    <template v-if="!successMsg">
                        <app-field-text
                            :field="{
                        id: 'lastName',
                        label: 'Nom *'
                        }"
                            :errorMessage='errorMessageLastName'
                            v-model="valueLastName"
                        ></app-field-text>
                        <app-field-text
                            :field="{
                        id: 'firstName',
                        label: 'Prénom *'
                        }"
                            :errorMessage='errorMessageFirstName'
                            v-model="valueFirstName"
                        ></app-field-text>
                        <app-field-text
                            :field="{
                        id: 'email',
                        label: 'E-mail *'
                        }"
                            :errorMessage='errorMessageEmail'
                            v-model="valueEmail"
                        ></app-field-text>
                        <small class="font-2">*champs obligatoires</small>
                    </template>
                </div>
                <div class="row justify-content-center">
                    <div class="col-6">
                        <a href="#" class="btn btn-tertiary w-100" @click.capture="onCancel">Fermer</a>
                    </div>
                    <div class="col-6" v-if="!successMsg">
                        <button class="btn btn-primary w-100" type="submit" v-if="!successMsg">Envoyer</button>
                    </div>
                </div>
            </form>
        </popin>
    </transition>
</template>

<script>
import popin from '../../composables/popin';
import { ref } from 'vue';
import { useField, useForm } from 'vee-validate';
import Gdpr from '../../services/Gdpr';
import dayjs from '../../dayjs';

export default {
    setup() {
        const { gdprFormIsOpen, showError } = popin;
        const { handleSubmit } = useForm();

        const successMsg = ref(null);
        const pending_scheduled_meeting_dates = ref(null);
        const isGdprLoading = ref(false);
        const isDialogLoading = ref(false);
        const errorMsg = ref('');

        const onCancel = function () {
            gdprFormIsOpen.value = false;
        };
        const onGdprConfirm = function () {
            gdprFormIsOpen.value = false;
        };

        const { errorMessage: errorMessageLastName, value: valueLastName } = useField('lastName', 'required');
        const { errorMessage: errorMessageFirstName, value: valueFirstName } = useField('firstName', 'required');
        const { errorMessage: errorMessageEmail, value: valueEmail } = useField('email', {
            required: true,
            email: true,
        });

        const onSubmit = handleSubmit((values) => {
            Gdpr.anonymizeGDPR({
                data: {
                    email: values.email,
                    first_name: values.firstName,
                    last_name: values.email,
                },
            }).subscribe(data => {
                successMsg.value = data.messages;
                if (data.pending_scheduled_meeting_dates) {
                    pending_scheduled_meeting_dates.value = data.pending_scheduled_meeting_dates;
                }
                isDialogLoading.value = false;
                valueLastName.value = ''
                valueFirstName.value = ''
                valueEmail.value = ''
            }, err => {
                isDialogLoading.value = false;
                showError(err)
                errorMsg.value = err.response.data.detail ? err.response.data.detail : 'Il y a un problème avec le serveur';
            });

        });

        const getFormatedDate = function (date) {
            return dayjs(date).format('dddd D MMMM YYYY à H:mm (Z)');
        };

        return {
            gdprFormIsOpen,
            onCancel,
            onGdprConfirm,
            successMsg,
            pending_scheduled_meeting_dates,
            onSubmit,
            errorMessageLastName,
            valueLastName,
            errorMessageFirstName,
            valueFirstName,
            errorMessageEmail,
            valueEmail,
            isGdprLoading,
            getFormatedDate,
            errorMsg,
        };
    },
};
</script>
