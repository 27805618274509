<template>
    <div class="panel p-md-5 d-flex flex-column">
        <p class="typo-3">Sélectionnez un rendez-vous</p>
        <transition name="component-fade" mode="out-in">
            <div class="row row--calendar gx-4"
                 v-if="showCalendar"
                 :class="{active:showStep2}">
                <div class="col-12 col-md-7 col--datepicker">
                    <div class="position-relative bg-xs px-0">
                        <div class="click-wrap" v-if="showStep2"
                             @click="onWrapClick()"></div>
                        <transition name="component-fade">
                            <div class="loader-wrapper" v-if="isLoading">
                                <div class="lds-dual-ring"></div>
                            </div>
                        </transition>
                        <vc-date-picker
                            ref="calendar1"
                            :class="{'vc-disable-weekend':!show_week_ends}"
                            :min-date="new Date()"
                            :max-date="maxDate"
                            :disabled-dates="disabledDates"
                            :attributes="availableDates"
                            color="apd"
                            v-model="vCalendarVModel"
                            @dayclick="onDayClick($event, 'calendar1')"
                            @update:from-page="onMonthChange($event, 'calendar1')"
                            nav-visibility="hidden"
                            is-inline
                            is-expanded
                            is-required
                        >
                        </vc-date-picker>
                    </div>
                </div>
                <transition name="component-fade">
                    <div class="col-10 col-md-5 col-sequences" :class="{active: bpUpMd || showStep2}">
                        <div class="bg-xs px-0 position-relative">
                            <transition name="component-fade">
                                <div class="loader-wrapper" v-if="isLoading">
                                    <div class="lds-dual-ring"></div>
                                </div>
                            </transition>
                            <vc-date-picker
                                ref="calendar2"
                                v-if="!bpUpMd"
                                class="vc--inline"
                                :class="{'vc-disable-weekend':!show_week_ends}"
                                :min-date="new Date()"
                                :max-date="maxDate"
                                :disabled-dates="disabledDates"
                                :attributes="availableDates"
                                color="apd"
                                v-model="vCalendarVModel"
                                @dayclick="onDayClick($event, 'calendar2')"
                                @update:pages="onMonthChange($event, 'calendar2')"
                                nav-visibility="hidden"
                                is-inline
                                is-required
                            >
                            </vc-date-picker>
                            <div class="scroll-wrap-calendar">
                                <template v-if="sequences.length">
                                    <div class="d-flex justify-content-center mb-3"
                                         v-for="sequence in sequences">
                                        <button class="btn btn-clock"
                                                :class="{active: formDate === sequence.from_full}"
                                                @click="updateDate(sequence.from_full)">
                                            <span class="btn-clock__label">
                                                {{ sequence.from }} - {{ sequence.to }}
                                                <span v-if="show_remaining_capacity_on_calendar" class="capacity-info">
                                                    {{
                                                        sequence.capacity
                                                    }}  {{ 1 == sequence.capacity ? 'place restante' : 'places restantes' }}
                                                </span>
                                            </span>
                                            <icon name="check"></icon>
                                        </button>
                                        <span style="display: none"
                                              data-cy="available_time_sequence">{{ sequence.from_full }}</span>
                                        <button class="btn btn-primary ms-3" v-show="formDate === sequence.from_full"
                                                data-cy="confirm-time"
                                                @click="onConfirm">Confirmer
                                        </button>
                                    </div>
                                </template>
                                <div class="text-center" v-else>
                                    <p class="typo-3 mt-3 px-3">Pas de créneau disponible pour ce mois</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div v-else class="bg-xs calendar-waiting">
                <div class="text-center" v-if="noDateAvailableMsg">
                    <p class="typo-2 my-5" v-html="noDateAvailableMsg"></p>
                </div>
                <div class="text-center" v-else>
                    <p class="typo-1 mt-5">Recherche du premier rendez-vous disponible</p>
                    <p class="typo-2 mt-2">Veuillez patienter</p>
                    <div class="lds-dual-ring mb-5"></div>
                </div>

            </div>
        </transition>
    </div>
</template>

<script>
import calendar from '../../composables/calendar';
import global from '../../composables/global';
import form from '../../composables/form';
import steps from '../../composables/steps';
import responsive from '../../composables/responsive';
import { ref } from 'vue';
import Meeting from '../../services/Meeting';
import popin from '../../composables/popin';
import { lastValueFrom } from 'rxjs';

export default {
    setup() {
        const {
            state: {
                meeting: {
                    id,
                    show_week_ends,
                    agency_selection_enabled,
                    consultant_selection_enabled,
                    show_remaining_capacity_on_calendar,
                },
            },
        } = global;
        const { updateDate, updateFormLocks } = form;
        const { bpUpMd } = responsive;
        const { goNextStep } = steps;
        const { setupCalendar } = calendar;
        const { showError } = popin;
        const isQuitting = ref(false);

        const {
            calendar1,
            calendar2,
            firstClickOnCalendar,
            showCalendar,
            formDate,
            agency,
            consultant,
            locks,
            isLoading,
            onDayClick,
            onMonthChange,
            getAvailableDates: availableDates,
            getDisabledDates: disabledDates,
            getSequences: sequences,
            vCalendarVModel,
            activeDay,
            noDateAvailableMsg,
            showStep2,
            onWrapClick,
            maxDate
        } = setupCalendar();

        async function onConfirm(e) {
            try {
                isLoading.value = true;
                const response = await lastValueFrom(Meeting.lockDate({
                    date: formDate.value,
                    meetingId: id,
                    agencyId: agency_selection_enabled ? agency.value?.id : null,
                    consultantId: consultant_selection_enabled ? consultant.value?.id : null,
                    previousLocks: locks.value,
                }));
                updateFormLocks(response);
                goNextStep();
                if (isQuitting.value) return;
                isQuitting.value = true;
                isLoading.value = false;
            } catch (e) {
                showError(e);
                setupCalendar();
                isLoading.value = false;
            }
        }

        return {
            onDayClick,
            onMonthChange,
            availableDates,
            disabledDates,
            sequences,
            show_week_ends,
            updateDate,
            formDate,
            onConfirm,
            vCalendarVModel,
            activeDay,
            isLoading,
            bpUpMd,
            onWrapClick,
            calendar1,
            calendar2,
            firstClickOnCalendar,
            showCalendar,
            noDateAvailableMsg,
            showStep2,
            show_remaining_capacity_on_calendar,
            maxDate
        };
    },
};
</script>
