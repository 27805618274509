import { reactive, readonly, computed, toRef } from 'vue';
import global from './global';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import form from './form';

const state = reactive({
    steps: [],
});

// Getters
const getSteps = computed(() => {
    const { state: { actionInProgress } } = global;
    const { hasCancelFields, hasUpdateFields } = form;

    return filter(state.steps, (step) => {
        if (step.slug === 'infos' && actionInProgress === 'update') {
            return !step.hideOn.includes(actionInProgress) && hasUpdateFields.value;
        }
        if (step.slug === 'infos' && actionInProgress === 'cancel') {
            return !step.hideOn.includes(actionInProgress) && hasCancelFields.value;
        }
        if (step.slug === 'agency' && form.isLockedAgency()) {
            return false;
        }
        if (step.slug === 'consultant' && form.isLockedConsultant()) {
            return false;
        }
        if (step.slug === 'calendar' && form.isLockedDate()) {
            return false;
        }
        return !step.hideOn.includes(actionInProgress);
    });
});

const getActiveStep = computed(() => {
    return find(getSteps.value, function (step) {
        return step.status === 1;
    });
});

const getActiveStepIndex = computed(() => {
    return findIndex(getSteps.value, function (step) {
        return step.status === 1;
    });
});

// Actions
function initSteps() {
    const { state: { meeting: { consultant_selection_enabled, agency_selection_enabled }, actionInProgress, scheduledMeeting } } = global;
    const lockedAgency = form.isLockedAgency();
    const lockedDate = form.isLockedDate();

    const stepArray = [
        {
            slug: 'calendar',
            label: 'Choisissez une date',
            status: 0,
            hideOn: ['cancel'],
        },
        {
            slug: 'infos',
            label: 'Renseignez vos informations',
            status: 0,
            hideOn: [],
        },
        {
            slug: 'confirmation',
            label: 'Confirmez votre rendez-vous',
            status: 0,
            hideOn: [],
        },
        {
            slug: 'summary',
            label: 'Récapitulatif',
            status: 0,
            hideOn: [],
        },
    ];

    if (consultant_selection_enabled) {
        stepArray.unshift({
            slug: 'consultant',
            label: 'Choisissez un conseiller',
            status: 0,
            hideOn: ['cancel'],
        });
    }

    if (agency_selection_enabled && !lockedAgency) {
        stepArray.unshift({
            slug: 'agency',
            label: 'Choisissez une agence',
            status: 0,
            hideOn: ['cancel'],
        });
    }

    state.steps = stepArray;
    if (actionInProgress === 'update' && lockedDate) {
        setActiveStep('confirmation');
    } else {
        setActiveStep((actionInProgress === 'update' || actionInProgress === 'survey' || actionInProgress === 'cancel') ? 'summary' : null);
    }
    // setActiveStep('calendar');
}

function goNextStep() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
    let activeIndex = getActiveStepIndex.value;
    if (activeIndex === (getSteps.value.length - 1)) {
        return;
    }
    activeIndex++;

    updateStepStatus(activeIndex);
}

function setActiveStep(stepSlug) {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
    if (!stepSlug) stepSlug = getSteps.value[0].slug;
    let stepFound = false;
    map(getSteps.value, step => {
        if (step.slug === stepSlug) {
            step.status = 1;
            stepFound = true;
        } else {
            step.status = stepFound ? 0 : 2;
        }
        return step;
    });
}

function updateStepStatus(activeIndex) {
    map(getSteps.value, (step, index) => {
        if (index < activeIndex) {
            step.status = 2;
        } else if (index === activeIndex) {
            step.status = 1;
        } else {
            step.status = 0;
        }
        return step;
    });
}

export default {
    state: readonly(state),
    initSteps,
    goNextStep,
    setActiveStep,
    getActiveStep,
    getSteps,
};
