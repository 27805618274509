import Axios from '../axios';

let Meeting = {
    getAvailability: function ({ params, meetingId, year, month }) {
        return Axios.request({
            method: 'get',
            url: '/meeting/' + meetingId + '/availabilities/' + year + '/' + month,
            params: params,
        });
    },
    lockDate: function ({ date, meetingId, agencyId, consultantId, previousLocks = [] }) {
        return Axios.request({
            method: 'post',
            url: '/meeting/' + meetingId + '/lock',
            data: {
                date,
                agencyId,
                consultantId,
                previousLocks,
            },
        });
    },
    createScheduledMeeting: function ({ data, meetingId }) {
        return Axios.request({
            method: 'post',
            url: '/meeting/' + meetingId + '/schedule',
            data,
        });
    },
    updateScheduledMeeting: function ({ data, meetingId, scheduledMeetingId }) {
        return Axios.request({
            method: 'put',
            url: '/meeting/' + meetingId + '/' + scheduledMeetingId,
            data,
        });
    },
    cancelScheduledMeeting: function ({ data, meetingId, scheduledMeetingId }) {
        return Axios.request({
            method: 'delete',
            url: '/meeting/' + meetingId + '/' + scheduledMeetingId,
            data,
        });
    },
    sendAttachment: function ({ data, meetingId, dynamicFieldId }) {
        return Axios.request({
            method: 'post',
            url: '/meeting/' + meetingId + '/schedule/attachment/' + dynamicFieldId,
            data,
        });
    },
    removeAttachment: function ({ data, meetingId, dynamicFieldId, attachmentId }) {
        return Axios.request({
            method: 'delete',
            url: '/meeting/' + meetingId + '/schedule/attachment/' + dynamicFieldId + '/' + attachmentId,
            data,
        });
    },
    rateScheduledMeeting: function ({ data, meetingId, scheduledMeetingId }) {
        return Axios.request({
            method: 'put',
            url: '/meeting/' + meetingId + '/' + scheduledMeetingId + '/rate',
            data,
        });
    },
};

export default Meeting;
