<template>
    <div class="mt-4 position-relative" data-form-item>
        <div class="row gx-3" :class="{'is-invalid':errorMessage}">
            <div class="col">
                <div class="input-file-label d-flex align-items-center">
                    <p class="mb-0">{{ label }}</p>
                </div>
            </div>
            <div class="col-auto d-flex align-items-center height-btn">
                <button class="btn btn-sm btn-outline-primary" @click.prevent="onAddClick"
                        v-if="!modelValue && !isLoading">
                    Ajouter
                    <icon name="trombone-outline" class="input-icon ms-2"></icon>
                </button>
                <div class="lds-dual-ring lds-dual-ring--small" v-if="isLoading"></div>
                <template v-if="modelValue && !isLoading">
                    <button class="btn btn-sm" @click.prevent="onRemoveClick">
                        <icon name="trash"></icon>
                    </button>
                    <icon name="check" class="text-primary"></icon>
                </template>
            </div>
        </div>
        <div class="invalid-tooltip">
            {{ errorMessage }}
        </div>
        <input-helper v-if="field.helper_text" v-html="field.helper_text" />
        <input type="file"
               :id="field.id"
               v-if="showInput"
               class="d-none"
               ref="fileRef"/>
    </div>
</template>

<script>
import { ref, toRef, onMounted, computed } from 'vue';
import truncate from 'lodash/truncate';
import Meeting from '../../services/Meeting';
import global from '../../composables/global';
import popin from '../../composables/popin';
import InputHelper from '../input-helper.vue';

export default {
    components: { InputHelper },
    props: {
        field: Object,
        errorMessage: String,
        modelValue: [Object, String],
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const fileRef = ref(null);
        const fileName = ref(null);
        const isLoading = ref(false);
        const showInput = ref(false);
        const field = toRef(props, 'field');
        const modelValue = toRef(props, 'modelValue');
        // if (modelValue.value) value.value = modelValue.value.id;
        // watch(modelValue, val => attachmentId.value = val.id);

        const label = computed(() => {
            if (modelValue.value) {
                return truncate(modelValue.value.original_file_name, {
                    'length': 26,
                    'omission': '...' + modelValue.value.original_file_name.split('.')[1],
                });
            } else {
                return field.value.label;
            }
        });

        onMounted(() => {
            initEvent();
        });

        function onFileChange($event) {
            const { state: { meeting } } = global;
            const { showError } = popin;
            if (!fileRef.value.files.length) return;
            isLoading.value = true;
            fileName.value = fileRef.value.files[0].name;

            const formData = new FormData();
            formData.append('file', fileRef.value.files[0]);
            Meeting.sendAttachment({
                data: formData,
                meetingId: meeting.id,
                dynamicFieldId: field.value.id,
            }).subscribe(data => {
                isLoading.value = false;
                emit('update:modelValue', data);
            }, err => {
                showError(err);
                isLoading.value = false;
                emit('update:modelValue', "");
            });
        }

        function onAddClick() {
            fileRef.value.click();
        }

        function onRemoveClick() {
            const { state: { meeting } } = global;
            const { showError } = popin;
            isLoading.value = true;
            Meeting.removeAttachment({
                meetingId: meeting.id,
                dynamicFieldId: field.value.id,
                attachmentId: modelValue.value.id,
            }).subscribe(() => {
                isLoading.value = false;
                emit('update:modelValue', "");
                initEvent();
            }, err => {
                showError(err);
                isLoading.value = false;
                emit('update:modelValue', "");
                initEvent();
            });
        }

        function initEvent() {
            showInput.value = false;
            setTimeout(() => {
                showInput.value = true;
                setTimeout(() => {
                    fileRef.value.addEventListener('change', onFileChange);
                }, 200);
            }, 200);
        }

        return {
            onAddClick,
            onRemoveClick,
            fileRef,
            label,
            isLoading,
            modelValue,
            showInput,
        };
    },
};
</script>
