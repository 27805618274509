<template>
    <div class="mt-1" data-form-item>
        <p v-for="mention in field.options" v-html="mention" class="mention mb-0 mt-1"></p>
    </div>
</template>

<script>

export default {
    props: {
        field: Object,
    },
    setup() {
        return {};
    },
};
</script>
