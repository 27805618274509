<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 16">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
              d="M15.818 11.69v2.25a1.5 1.5 0 01-1.635 1.5 14.843 14.843 0 01-6.472-2.302 14.625 14.625 0 01-4.5-4.5A14.842 14.842 0 01.908 2.135 1.5 1.5 0 012.401.5h2.25a1.5 1.5 0 011.5 1.29 9.63 9.63 0 00.525 2.108 1.5 1.5 0 01-.338 1.582l-.952.953a12 12 0 004.5 4.5l.952-.953a1.5 1.5 0 011.583-.337c.68.253 1.387.43 2.107.525a1.5 1.5 0 011.29 1.522z"
              clip-rule="evenodd"/>
    </svg>
</template>

<script>
export default {};
</script>
