<template>
    <div class="row mb-3">
        <div class="col-auto" style="width: 45px">
            <icon :name="iconName"></icon>
        </div>
        <div class="col p-marging">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    props: ['iconName'],
};
</script>
