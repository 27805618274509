<template>
    <template v-if="actionInProgress === 'create'">
        <div class="text-center">
            <icon name="validation" class="mb-3"></icon>
            <p class="confirm-msg">Merci ! Votre rendez-vous est confirmé.</p>
        </div>
    </template>
    <template v-if="actionInProgress === 'update'">
        <div class="text-center align-self-center">
            <icon :name="isUpdatingDone ? 'validation' : 'reload'" class="mb-3"></icon>
            <p class="confirm-msg" v-if="isUpdatingDone">Merci ! Votre rendez-vous est
                modifié.</p>
            <p class="confirm-msg" v-else>Vous souhaitez modifier ce rendez-vous ?</p>
        </div>
    </template>
    <template v-if="actionInProgress === 'cancel'">
        <div class="text-center align-self-center">
            <icon name="validation" class="mb-3"></icon>
            <p class="confirm-msg">Merci ! Votre rendez-vous est annulé.</p>
        </div>
    </template>
</template>

<script>
import { toRef } from 'vue';
import global from '../composables/global';

export default {
    setup() {
        const {
            state: stateGlobal,
        } = global;
        const actionInProgress = toRef(stateGlobal, 'actionInProgress');
        const isUpdatingDone = toRef(stateGlobal, 'isUpdatingDone');

        return {
            actionInProgress,
            isUpdatingDone,
        };
    },
};
</script>
