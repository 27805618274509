import { onMounted, reactive, readonly, ref, toRef, watch } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import mapStyle from './mapStyle';
import consultant from './consultant';
import form from './form';
import global from './global';
import popin from './popin';

import forEach from 'lodash/forEach';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';

const state = reactive({
    loader: null,
    listHtmlRef: null,
    mapHtmlRef: null,
    bodyColor: null,
    primaryColor: null,
});

// Outside of state because reactivity lose marker connection with the api
const markers = {};
let latLng = [];
let markerCluster = null;
let mapInstance = null;
let mapPosition = null;
let mapZoom = null;

function initGoogleMap({ onGoogleMapReady }) {
    state.bodyColor = getComputedStyle(document.documentElement).getPropertyValue('--body-color');
    state.primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');
    const mapRef = ref(null);
    const listRef = ref(null);
    state.mapHtmlRef = mapRef;
    state.listHtmlRef = listRef;

    onMounted(() => {
        const { state: { meeting: { enabled_agency_geolocation } } } = global;
        if (!enabled_agency_geolocation) return;
        loadSDK();
        if (!mapInstance) {
            state.loader.load().then(() => {
                loadMap();
                if (onGoogleMapReady) onGoogleMapReady();
            });
        } else {
            loadMap();
            if (onGoogleMapReady) onGoogleMapReady();
        }
    });

    return {
        onClickAgency,
        mapRef,
        listRef,

    };
}

const onClickAgency = (agency, centerMap = true) => {
    const { updateFormAgency } = form;
    updateFormAgency(agency);
    state.listHtmlRef.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
    if (!mapInstance) return;
    updateMarkerColor();
    if (centerMap) centerMapOnSelection();
};

function loadSDK() {
    const { state: { gkey } } = global;

    if (!mapInstance) {
        state.loader = new Loader({
            apiKey: gkey,
            version: 'weekly',
        });
    }
}

function loadMap() {
    const { getAgencies: agencies } = consultant;
    mapInstance = new google.maps.Map(state.mapHtmlRef, {
        center: { lat: 46.7209877, lng: 2 },
        zoom: 5.5,
        styles: mapStyle,
        disableDefaultUI: true,
    });
    window.apdMap = mapInstance;
    createMarkers();
    watch(agencies, (val) => {
        createMarkers();
    });
}

function createMarkers() {
    removeMarkers();
    const { getAgencies: agencies } = consultant;
    latLng = [];
    forEach(agencies.value, (agency) => {
        if ((agency.latitude === 0 && agency.longitude === 0) || isNil(agency.latitude) || isNil(agency.longitude)) {
            console.error('No position for this agency : ', agency);
            return;
        }
        markers[agency.id] = new google.maps.Marker({
            position: { lat: agency.latitude, lng: agency.longitude },
            label: getLabel(agency),
            map: mapInstance,
            icon: getIcon(agency),
            agency: agency,
        });

        google.maps.event.addListener(markers[agency.id], 'mouseover', () => onMouseEnter(agency));
        google.maps.event.addListener(markers[agency.id], 'mouseout', () => onMouseLeave(agency));
        google.maps.event.addListener(markers[agency.id], 'click', () => onClickAgency(agency));

        latLng.push({
            agency,
            latLng: new google.maps.LatLng(agency.latitude, agency.longitude),
        });
    });
    setMapBounds();
    if (latLng.length < 15) return;
    markerCluster = new MarkerClusterer(mapInstance, [], {
        maxZoom: 14,
        imagePath: `/images/clusters/m`,
    });
    markerCluster.setStyles(markerCluster.getStyles().map((style) => {
        style.textColor = 'white';
        return style;
    }));
    markerCluster.addMarkers(markers);
}

function removeMarkers() {
    if (markerCluster) markerCluster.clearMarkers();
    forEach(markers, (marker) => {
        marker.setMap(null);
    });
}

function updateMarkerColor() {
    forEach(markers, (marker) => {
        marker.setIcon(getIcon(marker.agency));
        marker.setLabel(getLabel(marker.agency));
    });
}

function onMouseEnter(agency) {
    if (!mapInstance) return;
    markers[agency.id].setIcon(getIcon(agency, true));
    markers[agency.id].setLabel(getLabel(agency, true));
}

function onMouseLeave(agency) {
    if (!mapInstance) return;
    markers[agency.id].setIcon(getIcon(agency));
    markers[agency.id].setLabel(getLabel(agency));
}

function getLabel(agency, active = false) {
    return {
        // text: agency.index.toString(),
        text: ' ',
        color: 'white',
        fontSize: active ? '1.2rem' : '1rem',
        fontFamily: '"Caros", sans-serif',
        fontWeight: '800',
    };
}

function getIcon(agency, active = false) {
    const { state: { payload } } = form;
    const selectedAgency = toRef(payload, 'agency');
    return {
        path:
            'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
        fillColor: active ? state.primaryColor : (selectedAgency.value && selectedAgency.value.id === agency.id) ? state.primaryColor : state.bodyColor,
        strokeWeight: 0,
        fillOpacity: 1,
        scale: active ? 0.6 : 0.52,
        labelOrigin: new google.maps.Point(0, -28),
    };
}

function setMapBounds(sortedLatLng, savePosition = true) {
    var latlngbounds = new google.maps.LatLngBounds();
    if (sortedLatLng) {
        for (var i1 = 0; i1 < sortedLatLng.length; i1++) {
            latlngbounds.extend(sortedLatLng[i1].latLng);
        }
    } else {
        for (var i2 = 0; i2 < latLng.length; i2++) {
            latlngbounds.extend(latLng[i2].latLng);
        }
    }

    mapInstance.fitBounds(latlngbounds);

    setTimeout(() => {
        if ((latLng.length === 1 || (sortedLatLng && sortedLatLng.length === 1)) && mapInstance.getZoom() > 12) {
            mapInstance.setZoom(12);
        }
        if (savePosition) {
            mapPosition = { lat: mapInstance.getCenter().lat(), lng: mapInstance.getCenter().lng() };
            mapZoom = mapInstance.getZoom();
        }
    }, 300);
}

function centerMapOnSelection() {
    const { state: { payload } } = form;
    const selectedAgency = toRef(payload, 'agency');
    if (selectedAgency.value) {
        mapInstance.panTo({ lat: selectedAgency.value.latitude, lng: selectedAgency.value.longitude });
        mapInstance.setZoom(16);
    } else {
        mapInstance.panTo(mapPosition);
        if (mapInstance.getZoom() !== mapZoom) {
            mapInstance.setZoom(mapZoom);
        }
    }
}

function activateGeolocation() {
    const { create, present } = popin;
    const { state: { meeting: { area: { support_phone_number } } } } = global;
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            const userLatLng = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
            latLng = map(latLng, (item) => {
                return {
                    ...item,
                    distance: getDistance(item.latLng, userLatLng),
                };
            });
            let sortedLatLng = sortBy(latLng, (item) => item.distance);
            sortedLatLng = filter(sortedLatLng, item => item.distance < 20000); // 20Km Max
            if (sortedLatLng.length) {
                setMapBounds(sortedLatLng, false);
                onClickAgency(sortedLatLng[0].agency, false);
            } else {
                create({
                    header: 'Information',
                    message: 'Il n\'a pas d\'adresse autour de votre position dans la limite des 20 km'
                        + (support_phone_number ? '<br />' + support_phone_number : ''),
                    confirmLabel: 'OK',
                });

                present();
            }
        }, (error) => {
            if (error.code === 1) {
                create({
                    header: 'Erreur',
                    message: 'Vous devez autoriser la localisation de votre navigateur pour activer cette fonctionnalité',
                    confirmLabel: 'OK',
                });

                present();
            }
        }, {
            enableHighAccuracy: true,
        });
    } else {
        create({
            header: 'Erreur',
            message: 'Votre navigateur ne supporte pas la géolocalisation',
            confirmLabel: 'OK',
        });

        present();
    }
}

var rad = function (x) {
    return x * Math.PI / 180;
};

var getDistance = function (p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter

    var dLat = rad(p2.lat - p1.lat());
    var dLong = rad(p2.lng - p1.lng());
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
};

export default {
    state: readonly(state),
    initGoogleMap,
    activateGeolocation,
    onMouseEnter,
    onMouseLeave,
    updateMarkerColor,
};
