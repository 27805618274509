<template>
    <div class="panel">
        <div class="row">
            <div :class="enabled_agency_geolocation ? 'col-12 col-md-6' : 'col-12'">
                <div class="p-md-5" :class="enabled_agency_geolocation ? 'pe-md-0' : ''">
                    <p class="typo-3">Sélectionnez {{ area.label_an_agency }}</p>
                    <div class="mb-3 mb-md-5 bg-xs" v-if="enabled_agency_geolocation || allowed_agency_filtering">
                        <div class="row">
                            <div v-if="enabled_agency_geolocation"
                                 :class="allowed_agency_filtering ? 'col-auto' : 'col-12'">
                                <button class="btn btn-outline-primary w-100" @click="onClickGeoLoc()">Autour de moi
                                </button>
                            </div>
                            <div class="col" v-if="allowed_agency_filtering">
                                <form @submit.prevent="onSubmit">
                                    <div class="gmap-form d-flex">
                                        <input type="text" class="gmap-input" v-model="search"
                                               placeholder="Filtrer"/>
                                        <button class="btn btn-primary gmap-form__submit">OK</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="scroll-wrap-agency"
                         ref="listRef"
                         :class="{'croll-wrap-agency--no-filters' : !enabled_agency_geolocation && !allowed_agency_filtering}">
                        <transition-group name="flip-list" tag="section">
                            <div v-for="agency in paginatedAgencies" :key="agency.id">
                                <div class="agency p-marging mb-3 mb-md-6 bg-xs"
                                     @click="onClickAgency(agency)"
                                     @mouseenter="onMouseEnter(agency)"
                                     @mouseleave="onMouseLeave(agency)"
                                     :class="{active:selectedAgency && agency.id === selectedAgency.id, light:selectedAgency && agency.id !== selectedAgency.id}">
                                    <div class="row gx-3">
                                        <div class="col-auto">
                                            <icon-marker class="marker-list" :number="agency.index"></icon-marker>
                                        </div>
                                        <div class="col">
                                            <p class="agency__name"><text-with-highlight :text="agency.name" :search="search" /></p>
                                            <p class="agency__info" v-if="!hidden_agency_address || selectedAgency && agency.id === selectedAgency.id"><text-with-highlight :text="!hidden_agency_address ? agency.address : '&nbsp;'" :search="search" /></p>
                                            <p class="agency__info" v-if="!hidden_agency_address || selectedAgency && agency.id === selectedAgency.id"><text-with-highlight :text="!hidden_agency_address ? agency.postal_code : '&nbsp;'" :search="search" /></p>
                                            <p class="agency__info" v-if="!hidden_agency_address || selectedAgency && agency.id === selectedAgency.id"><text-with-highlight :text="!hidden_agency_address ? agency.city : '&nbsp;'" :search="search" /></p>
                                            <p class="agency__info" v-if="!hidden_agency_address || selectedAgency && agency.id === selectedAgency.id">{{ agency.phone_number }}</p>
                                        </div>
                                        <div class="col-auto position-relative">
                                            <icon name="check"
                                                  v-if="selectedAgency && agency.id === selectedAgency.id"></icon>
                                            <icon name="carret-down" class="icon-carret-right" v-else></icon>
                                            <transition name="submit-fade" mode="out-in" v-if="bpUpMd">
                                                <button class="btn btn-primary agency__confirm"
                                                        @click.stop="onConfirm"
                                                        data-cy="agency__confirm"
                                                        v-show="selectedAgency && agency.id === selectedAgency.id">
                                                    Confirmer
                                                </button>
                                            </transition>
                                        </div>
                                    </div>
                                </div>
                                <transition name="submit-fade" mode="out-in" v-if="!bpUpMd">
                                    <button class="btn btn-primary w-100 mb-3"
                                            @click.stop="onConfirm"
                                            data-cy="agency__confirm"
                                            v-show="selectedAgency && agency.id === selectedAgency.id">Confirmer
                                    </button>
                                </transition>
                            </div>
                        </transition-group>
                        <p v-if="!paginatedAgencies.length" class="text-start font-4 bg-xs">Pas de résultats</p>
                        <button class="btn btn-outline-tertiary w-100 mb-3"
                                v-if="!bpUpMd && showMoreButtonAgency"
                                @click.stop="onShowMoreAgency()">Afficher plus
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6 border-2" v-if="enabled_agency_geolocation">
                <div class="gmap-wrapper" ref="mapRef"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, toRef, onBeforeUnmount, onMounted } from 'vue';
import maps from '../../composables/maps';
import global from '../../composables/global';
import form from '../../composables/form';
import steps from '../../composables/steps';
import calendar from '../../composables/calendar';
import consultant from '../../composables/consultant';
import responsive from '../../composables/responsive';
import { find } from 'lodash';
import TextWithHighlight from "../TextWithHighlight.vue";
import popin from "../../composables/popin";

export default {
    components: {TextWithHighlight},
    setup() {
        const { initGoogleMap, activateGeolocation, onMouseEnter, onMouseLeave, updateMarkerColor } = maps;
        const { state: { meeting: { allowed_agency_filtering, enabled_agency_geolocation, area, hidden_agency_address, allowed_override_of_preassigned_agency, agency_not_available_message } } } = global;
        const { state: { payload }, updateFormAgency, updateFormConsultant, updateFormPostalCode, lockAgency, isLockedConsultant } = form;
        const { showError } = popin;
        const {
            getSortedAgencies: paginatedAgencies,
            getAgencies: allAgencies,
            queryAgency,
            updateQueryAgency,
            onShowMoreAgency,
            showMoreButtonAgency,
        } = consultant;
        const { goNextStep } = steps;
        const { resetCalendar } = calendar;
        const { bpUpMd } = responsive;

        const selectedAgency = toRef(payload, 'agency');
        const payloadConsultant = toRef(payload, 'consultant');

        if (payloadConsultant.value) updateFormAgency(payloadConsultant.value.agency);

        updateFormPostalCode();

        const search = ref('');
        const isQuitting = ref(false);

        const { listRef, mapRef, onClickAgency } = initGoogleMap({
            onGoogleMapReady: () => {
                codePostalPreselectAgency();
            },
        });

        onBeforeUnmount(() => {
            if (!isLockedConsultant()) {
                // Reset Consultant
                updateFormConsultant();
            }
            resetCalendar();
            updateQueryAgency('');
        });

        function onSubmit() {
            updateFormAgency(null);
            updateQueryAgency(search.value);
            updateMarkerColor();
        }

        function onClickGeoLoc() {
            search.value = '';
            onSubmit();
            activateGeolocation();
        }

        function onConfirm() {
            if (isQuitting.value) return;
            isQuitting.value = true;
            goNextStep();
        }

        const codePostalPreselectAgency = () => {
            const postalCode = localStorage.getItem('apendayPostalCode');

            if (postalCode && postalCode.length > 0) {
                const agency = find(allAgencies.value, function (o) {
                    if (o.managed_postal_codes && o.managed_postal_codes.length) {
                        return o.managed_postal_codes.includes(postalCode);
                    }
                    if (o.postal_code) {
                        return o.postal_code === postalCode;
                    }
                    return false;
                });
                if (agency) setTimeout(() => {
                    if (allowed_override_of_preassigned_agency) {
                        onClickAgency(agency);
                    } else {
                        updateFormAgency(agency)
                        lockAgency();
                        goNextStep();
                    }
                }, 300);
                if (!agency) {
                    showError({header: '', customErrorMsg: agency_not_available_message})
                }
                localStorage.removeItem('apendayPostalCode');
            }
        };

        if (!enabled_agency_geolocation) {
            codePostalPreselectAgency();
        }

        return {
            mapRef,
            selectedAgency,
            onConfirm,
            queryAgency,
            search,
            allowed_agency_filtering,
            enabled_agency_geolocation,
            hidden_agency_address,
            onClickGeoLoc,
            paginatedAgencies,
            onSubmit,
            listRef,
            onClickAgency,
            onMouseEnter,
            onMouseLeave,
            area,
            bpUpMd,
            onShowMoreAgency,
            showMoreButtonAgency,
        };
    },
};
</script>
