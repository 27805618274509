<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="apenday-logo" viewBox="0 0 88 17" width="88" height="17" :class="powered_by_logo_style">
        <path fill="#333" fill-opacity=".62" d="M83.502 8.47a3.504 3.504 0 00-3.498 3.51c0 .422.074.828.21 1.203h6.577c.136-.375.21-.78.21-1.203a3.504 3.504 0 00-3.499-3.51z" opacity=".6"/>
        <path fill="url(#paint0_linear)" d="M4.264 3.618c1.146 0 2.11.434 2.832 1.167v-.95h3.246v9.37H7.095v-.95c-.721.733-1.685 1.167-2.83 1.167C1.756 13.422 0 11.278 0 8.52c0-2.76 1.757-4.902 4.264-4.902zm.92 6.9c1.064 0 1.912-.867 1.912-1.998 0-1.13-.848-1.999-1.911-1.999-1.064 0-1.911.868-1.911 1.999-.002 1.13.847 1.999 1.91 1.999z"/>
        <path fill="url(#paint1_linear)" d="M18.652 13.422c-1.154 0-2.118-.434-2.83-1.167v5.147h-3.247V3.835h3.246v.95c.713-.733 1.677-1.167 2.832-1.167 2.515 0 4.264 2.144 4.264 4.902s-1.749 4.902-4.265 4.902zm-.91-6.9c-1.072 0-1.92.867-1.92 1.998s.847 1.999 1.92 1.999c1.064 0 1.902-.868 1.902-1.999 0-1.131-.838-1.999-1.902-1.999z"/>
        <path fill="url(#paint2_linear)" d="M29.614 11.024c1.199 0 2.11-.316 2.956-1.021l1.209 1.502c-1.01 1.131-2.345 1.917-4.247 1.917-3.264 0-5.464-2.144-5.464-4.902s2.245-4.902 5.166-4.902c2.867 0 4.986 2.107 4.986 4.766 0 .334-.063.724-.154 1.012h-6.672c.183 1.05.94 1.628 2.22 1.628zm-2.19-3.536h3.462c-.18-.95-.757-1.474-1.703-1.474-.92 0-1.552.516-1.76 1.474z"/>
        <path fill="url(#paint3_linear)" d="M39.214 13.205h-3.336v-9.37h3.336v.924c.605-.742 1.461-1.104 2.453-1.104 1.902 0 3.417 1.357 3.417 3.582v5.968H41.75v-5.3c0-.77-.506-1.375-1.236-1.375-.703 0-1.299.488-1.299 1.637v5.038z"/>
        <path fill="url(#paint4_linear)" d="M50.956 3.618c1.145 0 2.11.434 2.83 1.167V0h3.247v13.205h-3.246v-.95c-.722.733-1.686 1.167-2.831 1.167-2.507 0-4.265-2.144-4.265-4.902-.001-2.76 1.758-4.902 4.265-4.902zm.919 6.9c1.063 0 1.91-.867 1.91-1.998 0-1.13-.847-1.999-1.91-1.999-1.064 0-1.911.868-1.911 1.999 0 1.13.847 1.999 1.91 1.999z"/>
        <path fill="url(#paint5_linear)" d="M63.017 3.618c1.145 0 2.109.434 2.83 1.167v-.95h3.247v9.37h-3.246v-.95c-.722.733-1.686 1.167-2.831 1.167-2.507 0-4.265-2.144-4.265-4.902 0-2.76 1.758-4.902 4.264-4.902zm.918 6.9c1.064 0 1.911-.867 1.911-1.998 0-1.13-.847-1.999-1.91-1.999-1.064 0-1.911.868-1.911 1.999 0 1.13.847 1.999 1.91 1.999z"/>
        <path fill="url(#paint6_linear)" d="M80.103 3.835l-4.697 13.567h-3.605l1.52-4.197-3.035-9.37h3.489l1.352 4.5 1.38-4.5h3.596z"/>
        <defs>
            <linearGradient id="paint0_linear" x1="121.106" x2="-12.245" y1="8.701" y2="8.701" gradientUnits="userSpaceOnUse">
                <stop stop-opacity="0"/>
                <stop offset=".197" stop-color="#151515" stop-opacity=".197"/>
                <stop offset=".429" stop-color="#262626" stop-opacity=".429"/>
                <stop offset=".684" stop-color="#303030" stop-opacity=".684"/>
                <stop offset="1" stop-color="#333"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="121.106" x2="-12.245" y1="8.701" y2="8.701" gradientUnits="userSpaceOnUse">
                <stop stop-opacity="0"/>
                <stop offset=".197" stop-color="#151515" stop-opacity=".197"/>
                <stop offset=".429" stop-color="#262626" stop-opacity=".429"/>
                <stop offset=".684" stop-color="#303030" stop-opacity=".684"/>
                <stop offset="1" stop-color="#333"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="121.106" x2="-12.245" y1="8.701" y2="8.701" gradientUnits="userSpaceOnUse">
                <stop stop-opacity="0"/>
                <stop offset=".197" stop-color="#151515" stop-opacity=".197"/>
                <stop offset=".429" stop-color="#262626" stop-opacity=".429"/>
                <stop offset=".684" stop-color="#303030" stop-opacity=".684"/>
                <stop offset="1" stop-color="#333"/>
            </linearGradient>
            <linearGradient id="paint3_linear" x1="121.106" x2="-12.245" y1="8.701" y2="8.701" gradientUnits="userSpaceOnUse">
                <stop stop-opacity="0"/>
                <stop offset=".197" stop-color="#151515" stop-opacity=".197"/>
                <stop offset=".429" stop-color="#262626" stop-opacity=".429"/>
                <stop offset=".684" stop-color="#303030" stop-opacity=".684"/>
                <stop offset="1" stop-color="#333"/>
            </linearGradient>
            <linearGradient id="paint4_linear" x1="121.106" x2="-12.245" y1="8.7" y2="8.7" gradientUnits="userSpaceOnUse">
                <stop stop-opacity="0"/>
                <stop offset=".197" stop-color="#151515" stop-opacity=".197"/>
                <stop offset=".429" stop-color="#262626" stop-opacity=".429"/>
                <stop offset=".684" stop-color="#303030" stop-opacity=".684"/>
                <stop offset="1" stop-color="#333"/>
            </linearGradient>
            <linearGradient id="paint5_linear" x1="121.106" x2="-12.245" y1="8.701" y2="8.701" gradientUnits="userSpaceOnUse">
                <stop stop-opacity="0"/>
                <stop offset=".197" stop-color="#151515" stop-opacity=".197"/>
                <stop offset=".429" stop-color="#262626" stop-opacity=".429"/>
                <stop offset=".684" stop-color="#303030" stop-opacity=".684"/>
                <stop offset="1" stop-color="#333"/>
            </linearGradient>
            <linearGradient id="paint6_linear" x1="121.106" x2="-12.245" y1="8.7" y2="8.7" gradientUnits="userSpaceOnUse">
                <stop stop-opacity="0"/>
                <stop offset=".197" stop-color="#151515" stop-opacity=".197"/>
                <stop offset=".429" stop-color="#262626" stop-opacity=".429"/>
                <stop offset=".684" stop-color="#303030" stop-opacity=".684"/>
                <stop offset="1" stop-color="#333"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
import global from '../composables/global';

export default {
    setup() {
        const {state: {meeting: {area: {powered_by_logo_style}}}} = global;
        return {
            powered_by_logo_style
        };
    }
};
</script>
