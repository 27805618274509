<template>
    <div class="panel p-md-5">
        <p class="typo-3">Sélectionnez {{ area.label_aconsultant }}</p>
        <div class="bg-xs mb-3 mb-md-6" v-if="allowed_empty_consultant_selection || allowed_consultant_filtering">
            <div class="row">
                <div class="col-12 col-md-auto" v-if="allowed_empty_consultant_selection">
                    <button class="btn btn-outline-primary w-100 mb-3 mb-md-0" data-cy="skip_consultant_selection" @click="noPreferences">Sans préférence
                    </button>
                </div>
                <div class="col" v-if="allowed_consultant_filtering">
                    <div class="input-group input-group--consultant mb-3">
                        <label class="input-group-text input-group-text--consultant">
                            <icon name="filter"></icon>
                        </label>
                        <app-field-text class="form-filter-consultants"
                                          v-model="search"
                                          :field="{label:'Rechercher'}">
                        </app-field-text>
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll-wrap-consultant">
            <template v-for="consultant in consultants" :key="consultant.last_name + consultant.first_name">
                <div class="consultant mb-3 bg-xs"
                     :class="{active: selectedConsultant && selectedConsultant.id === consultant.id,
                     light:selectedConsultant && selectedConsultant.id !== consultant.id}"
                     v-if="!search || isMatchingSearch(consultant)"
                     @click="onClickConsultant(consultant)">
                    <transition mode="out-in" v-if="bpUpMd">
                        <icon name="check" class="me-4"
                              v-show="selectedConsultant && selectedConsultant.id === consultant.id"></icon>
                    </transition>
                    <icon name="user" class="icon-user--listing"></icon>
                    <div class="consultant__label ms-4">
                        <span class="consultant__name">
                            <text-with-highlight :text="consultant.full_name" :search="search" />
                        </span>
                        <template v-if="(show_consultant_job || allowed_consultant_filtering) && consultant.function?.name">
                            <span class="consultant__name"><template v-if="bpUpMd">,&nbsp;</template></span><span
                            class="consultant__fonction">
                             <text-with-highlight :text="consultant.function.name" :search="search" />
                        </span>
                        </template>
                    </div>
                    <transition mode="out-in" v-if="!bpUpMd">
                        <icon name="check" class="me-4"
                              v-show="selectedConsultant && selectedConsultant.id === consultant.id"></icon>
                    </transition>
                    <transition mode="out-in" v-if="bpUpMd">
                        <button class="btn btn-primary consultant__confirm"
                                data-cy="consultant__confirm"
                                v-if="selectedConsultant && selectedConsultant.id === consultant.id"
                                @click.stop="onConfirm">Confirmer
                        </button>
                    </transition>
                </div>
                <transition name="submit-fade" mode="out-in" v-if="!bpUpMd">
                    <button class="btn btn-primary w-100 mb-3"
                            data-cy="consultant__confirm"
                            v-if="selectedConsultant && selectedConsultant.id === consultant.id"
                            @click.stop="onConfirm">Confirmer
                    </button>
                </transition>
            </template>
            <button class="btn btn-outline-tertiary w-100 mb-3"
                    v-if="!bpUpMd && showMoreButtonConsultant"
                    @click.stop="onShowMoreConsultant()">Afficher plus
            </button>
        </div>
    </div>
</template>

<script>

import { toRef, ref, onBeforeUnmount, watch, computed } from 'vue';
import map from 'lodash/map';
import global from '../../composables/global';
import consultant from '../../composables/consultant';
import form from '../../composables/form';
import steps from '../../composables/steps';
import responsive from '../../composables/responsive';
import calendar from '../../composables/calendar';
import AppFieldText from "../form/app-field-text.vue";
import TextWithHighlight from "../TextWithHighlight.vue";

export default {
    components: {TextWithHighlight, AppFieldText},
    setup() {
        const {
            state: {
                meeting: {
                    allowed_empty_consultant_selection,
                    allowed_consultant_filtering,
                    show_consultant_job,
                    area,
                },
            },
        } = global;
        const {
            getFonctions: fonctions,
            getPagedConsultants: consultants,
            onShowMoreConsultant,
            showMoreButtonConsultant,
            setupConsultant,
        } = consultant;
        const { state: { payload }, updateFormConsultant } = form;
        const { goNextStep } = steps;
        const { resetCalendar } = calendar;
        const { bpUpMd } = responsive;

        const selectedConsultant = toRef(payload, 'consultant');
        const selectedAgency = toRef(payload, 'agency');
        const search = ref(null);
        const isQuitting = ref(false);
        const pristine = ref(true);

        watch(search,
            (val) => {
                updateFormConsultant();
            });

        setupConsultant();

        const noPreferences = function () {
            updateFormConsultant();
            goNextStep();
        };

        onBeforeUnmount(() => {
            // Reset Calendar
            if (!pristine.value) resetCalendar();
        });

        function onConfirm() {
            if (isQuitting.value) return;
            isQuitting.value = true;
            goNextStep();
        }

        function onClickConsultant(consultant) {
            updateFormConsultant(consultant);
            pristine.value = false;
        }

        function isMatchingSearch(consultant) {
            const pattern = new RegExp(search.value, 'giu');
            const searchValues = [consultant.full_name];
            if (show_consultant_job) {
                searchValues.push(consultant.function?.name ?? '');
            }

            for (let searchField of searchValues) {
                if (searchField.match(pattern)) {
                    return true;
                }
            }

            return false;
        }

        return {
            selectedConsultant,
            selectedAgency,
            updateFormConsultant,
            onConfirm,
            allowed_empty_consultant_selection,
            allowed_consultant_filtering,
            search,
            consultants,
            show_consultant_job,
            noPreferences,
            area,
            onClickConsultant,
            bpUpMd,
            onShowMoreConsultant,
            showMoreButtonConsultant,
            isMatchingSearch,
        };
    },
};
</script>
