<template>
    <button v-if="!showInput" @click="onClickOpen" type="button" class="btn btn-tertiary btn-sm mt-1">
        Ajouter un invité
    </button>
    <div v-else>
        <p class="mb-1 typo-2 font-4">E-mail(s) invité(s)</p>
        <div class="row g-0">
            <div
                class="col-auto"
                v-for="(item, index) in attendees"
                @click="deleteItem(index)"
                :key="item.email"
            >
                <div class="badge removable">
                    <span>{{item.email}} x</span>
                </div>
            </div>
        </div>
        <form @submit.prevent="onSubmit" class="mt-2" v-if="attendees.length < limit">
            <div class="d-flex">
                <div class="position-relative flex-grow-1">
                    <input
                        ref="input"
                        v-model="value"
                        type="text"
                        class="form-control form-control-sm"
                        name="email"
                        autocomplete="off"
                        placeholder="Adresse email..."
                        :class="{'is-invalid':errorMessage && trySubmit}"
                    >
                    <div class="invalid-tooltip bottom" v-if="errorMessage && trySubmit">
                        {{ errorMessage }}
                    </div>
                </div>
                <button type="submit" class="btn btn-primary px-3 py-0">
                    OK
                </button>
            </div>
        </form>
    </div>

</template>

<script setup>
import { nextTick, ref } from 'vue';
import form from '../composables/form';
import { useField } from 'vee-validate';
import { string } from 'yup';

const { updateExtraAttendees } = form;

const attendees = ref([]);
const showInput = ref(false);
const trySubmit = ref(false);
const input = ref(null);
const limit = 5;

const { value, errorMessage, meta, resetField } = useField('email', string().email('Ce champ doit être une adresse e-mail valide'), {
    standalone: true,
});

const focusInput = () => {
    nextTick(() => {
        if (input.value) input.value.focus();
    });
};

const onSubmit = () => {
    if (!value.value) return
    trySubmit.value = true;
    if (meta.valid) {
        attendees.value.push({ 'email': value.value });
        resetField();
        trySubmit.value = false;
        updateExtraAttendees(attendees.value);
        focusInput();
    }
};

const deleteItem = function (index) {
    attendees.value.splice(index, 1);
    updateExtraAttendees(attendees.value);
};

const onClickOpen = () => {
    showInput.value = true;
    focusInput();
};

</script>
