<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19">
        <path clip-rule="evenodd" d="M9 16.51c4.142 0 7.5-3.36 7.5-7.505A7.502 7.502 0 009 1.501c-4.142 0-7.5 3.36-7.5 7.504A7.502 7.502 0 009 16.509z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 4.502v4.503l2.25 2.251" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {};
</script>
