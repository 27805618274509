<template>
    <transition name="popin">
        <popin v-if="gdprIsOpen" @bgClick="onCancel">
            <p class="typo-1 text-center mb-7">Politique de confidentialité</p>
            <div class="font-4 mb-5 popin__message popin__message--rgpd" v-html="privacy_policy"></div>
            <div class="d-flex justify-content-center mb-3" v-if="actionInProgress !== 'create' || isUpdatingDone">
                <button class="btn btn-tertiary" @click="onGdprConfirm" data-cy="gdpr__request">Demande de suppression des données personnelles
                </button>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-primary" @click="onConfirm">Fermer
                </button>
            </div>
        </popin>
    </transition>
</template>

<script>
import popin from '../../composables/popin';
import global from '../../composables/global';
import { toRef } from 'vue';

export default {
    setup() {
        const { gdprIsOpen, gdprFormIsOpen } = popin;
        const { state: { config: { privacy_policy } }, state: stateGlobal } = global;
        const actionInProgress = toRef(stateGlobal, 'actionInProgress');
        const isUpdatingDone = toRef(stateGlobal, 'isUpdatingDone');

        const onConfirm = function () {
            gdprIsOpen.value = false;
        };
        const onCancel = function () {
            gdprIsOpen.value = false;
        };
        const onGdprConfirm = function () {
            gdprIsOpen.value = false;
            setTimeout(() => {
                gdprFormIsOpen.value = true;
            }, 100);
        };

        return {
            gdprIsOpen,
            onConfirm,
            onCancel,
            onGdprConfirm,
            privacy_policy,
            actionInProgress,
            isUpdatingDone,
        };
    },
};
</script>
