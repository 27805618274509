<template>
    <div class="mt-4 position-relative" :class="{'readonly': fieldLocked}" data-form-item>
        <select class="form-select"
                v-if="bpDownXs"
                :class="{empty:!modelValue, 'is-invalid':errorMessage}"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :aria-label="field.label"
                :disabled="fieldLocked"
        >
            <option value="" selected>{{ field.label }}</option>
            <option
                v-for="option in field.options"
                :value="option">{{ option }}
            </option>
        </select>

        <div class="form-select custom-select" :class="{empty:!modelValue, 'is-invalid':errorMessage}"
             v-else
             @click="onFieldClick()" ref="customSelectRef">
            <span>{{ selectedLabel }}</span>
            <teleport to="body">
                <transition name="custom-select">
                    <div class="custom-select__options" v-if="isOpen"
                         :style="'top: ' + top + 'px; left: '+ left +'px; width: ' + width + 'px; max-height: ' + maxHeight + 'px'">
                        <div class="custom-select__option custom-select__option--default"
                             v-if="!field.required"
                             @click="onSelect()">{{ field.label }}
                        </div>
                        <div class="custom-select__option"
                             v-for="option in field.options"
                             @click="onSelect(option)"
                             :value="option">
                            {{ option }}
                        </div>
                    </div>
                </transition>
            </teleport>
        </div>
        <div class="invalid-tooltip">
            {{ errorMessage }}
        </div>
        <input-helper v-if="field.helper_text" v-html="field.helper_text" />
    </div>
</template>

<script>
import { computed, toRef, ref, onMounted, onUnmounted, watch, toRefs } from 'vue';
import responsive from '../../composables/responsive';
import isEmpty from 'lodash/isEmpty';
import InputHelper from '../input-helper.vue';

export default {
    components: { InputHelper },
    props: {
        field: Object,
        errorMessage: String,
        modelValue: String,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { bpDownXs } = responsive;
        const top = ref(0);
        const left = ref(0);
        const width = ref(0);
        const maxHeight = ref(200);
        const isOpen = ref(false);
        const { field, modelValue } = toRefs(props);
        const customSelectRef = ref(null);
        let fieldLocked = field.value.locked_when_prefilled;

        if (isEmpty(modelValue.value) && fieldLocked) {
            fieldLocked = false;
        }

        watch(isOpen, (val) => {
            const selectInfos = customSelectRef.value.getBoundingClientRect();
            top.value = selectInfos.top + selectInfos.height;
            left.value = selectInfos.left;
            width.value = selectInfos.width;
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            maxHeight.value = vh - selectInfos.bottom - 20;
        });

        const selectedLabel = computed(() => {
            return modelValue.value ?? field.value.label;
        });

        function onFieldClick() {
            if (fieldLocked) return;
            isOpen.value = !isOpen.value;
        }

        function onSelect(option) {
            emit('update:modelValue', option);
            isOpen.value = false;
        }

        onMounted(() => {
            document.body.addEventListener('click', clickOutsideEvent);
        });

        onUnmounted(() => {
            document.body.removeEventListener('click', clickOutsideEvent);
        });

        function clickOutsideEvent(event) {
            if (!customSelectRef.value) return;
            if (!(customSelectRef.value === event.target || customSelectRef.value.contains(event.target))) {
                isOpen.value = false;
            }
        }

        return {
            selectedLabel,
            isOpen,
            onFieldClick,
            customSelectRef,
            onSelect,
            top,
            left,
            width,
            maxHeight,
            bpDownXs,
            fieldLocked,
        };
    },
};
</script>
