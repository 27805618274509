<template>
    <span v-html="highlight()"></span>
</template>

<script>
import {defineComponent, ref} from 'vue';

export default defineComponent({
    props: {
        text: String,
        search: String,
    },
    setup(props) {
        const highlight = function () {
            if (props.search)
                return props.text.replace(new RegExp(props.search, 'gi'), '<strong class="highlight">$&</strong>');

            return props.text;
        }

        return {
            highlight
        }
    }
})
</script>
