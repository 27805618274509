import Axios from 'axios-observable';

const axios = Axios.create({
    baseURL: window.base_url + 'api/front',
    timeout: 20000,
    auth: {
        username: 'front_api',
        password: 'Front4piP4ssw0rd!',
    },
});

axios.interceptors.response.use(function (response) {
    return response.data;
}, function (intercept) {

    if (intercept.response) {
        if (intercept.response.data.error) {
            intercept.customErrorMsg = intercept.response.data.error.message;
        } else if (intercept.response.data.detail && intercept.response.data.status) {
            intercept.customErrorMsg = `${intercept.response.data.detail} (code : ${intercept.response.data.status})`;
        } else if (intercept.response.data.title && intercept.response.data.status) {
            intercept.customErrorMsg = `${intercept.response.data.title} (code : ${intercept.response.data.status})`;
        } else if (intercept.response.data.errors) {
            intercept.customErrorMsg = intercept.response.data.errors[0].message;
        } else {
            intercept.customErrorMsg = 'Une erreur technique est survenue';
        }
    } else {
        // Timeout Error
        intercept.customErrorMsg = 'Une erreur technique est survenue, merci de réessayer ultérieurement';
    }

    return Promise.reject(intercept);
});

export default axios;
