<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
        <path clip-rule="evenodd" d="M18.182 5.253l-5.25 3.752 5.25 3.752V5.253v0z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path clip-rule="evenodd" d="M1.682 5.752a2 2 0 012-2h7.25a2 2 0 012 2v6.506a2 2 0 01-2 2h-7.25a2 2 0 01-2-2V5.752z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {};
</script>
