<template>
    <div class="mt-4 position-relative" :class="{'readonly': fieldLocked}" data-form-item>
        <p class="form-check-multiple__label">{{ field.label }}</p>
        <div :class="{'is-invalid':errorMessage}">
            <template v-for="(option, index) in field.options" :key="option">
                <div class="form-check form-check-inline mb-3 position-relative">
                    <input
                        class="form-check-input"
                        :class="{'is-invalid':errorMessage}"
                        type="radio"
                        :name="field.id"
                        :disabled="fieldLocked"
                        :id="field.id + '_' + index"
                        :value="option"
                        :checked="modelValue === option"
                        @input="$emit('update:modelValue', $event.target.value)"
                    >
                    <label class="form-check-label" :for="field.id + '_' + index">
                        {{ option }}
                    </label>
                </div>
            </template>
        </div>
        <div class="invalid-tooltip">
            {{ errorMessage }}
        </div>
        <input-helper v-if="field.helper_text" v-html="field.helper_text" />
    </div>
</template>

<script>

import { toRefs } from 'vue';
import isEmpty from 'lodash/isEmpty';
import InputHelper from '../input-helper.vue';

export default {
    components: { InputHelper },
    props: {
        field: Object,
        errorMessage: String,
        modelValue: String,
    },
    emits: ['update:modelValue'],
    setup(props) {
        const { field, modelValue } = toRefs(props);
        let fieldLocked = field.value.locked_when_prefilled
        if (isEmpty(modelValue.value) && fieldLocked) {
            fieldLocked = false
        }

        return {
            fieldLocked
        };
    },
};
</script>
