<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 21">
        <path d="M17.889 20v-2.111a4.222 4.222 0 00-4.222-4.223H5.222A4.222 4.222 0 001 17.89v2.11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path clip-rule="evenodd" d="M9.444 9.444a4.222 4.222 0 100-8.444 4.222 4.222 0 000 8.444z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {};
</script>
