import router from './router';
import { init, BrowserTracing, vueRouterInstrumentation } from '@sentry/vue';

export const initSentry = (app) => {
    init({
        app,
        enabled: process.env.NODE_ENV === 'production',
        dsn: 'https://5f0aa03a5f064b59b2ac719ebfb64b34@o1290556.ingest.sentry.io/4504775502266368',
        // release: getEnv().releaseName,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: vueRouterInstrumentation(router),
                tracePropagationTargets: ['app.apenday.com', 'preprod.app.apenday.com', /^\//],
            }),
        ],
        tracesSampleRate: 0.3,
        environment: 'prod',
    });
};

