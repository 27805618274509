<template>
    <div class="container">
        <div class="app-content">
            <top-header></top-header>
            <div class="row">
                <div class="col-md-auto col-step" v-if="actionInProgress !== 'survey'">
                    <steps></steps>
                </div>
                <div class="col">
                    <transition name="component-fade" mode="out-in">
                        <component :is="'panel-'+getActiveStep.slug"></component>
                    </transition>
                </div>
            </div>
            <a href="https://apenday.com/" target="_blank" v-if="show_powered_by && bpUpMd">
                <logo-apenday></logo-apenday>
            </a>
        </div>
    </div>
</template>

<script>
import global from '../composables/global';
import steps from '../composables/steps';
import responsive from '../composables/responsive';

export default {
    setup() {
        const { state: { meeting: { area: { show_powered_by } }, actionInProgress } } = global;
        const { getActiveStep } = steps;
        const { bpUpMd } = responsive;

        return {
            show_powered_by,
            getActiveStep,
            actionInProgress,
            bpUpMd
        };
    },
};
</script>
