import form from './form';
import global from './global';
import dayjs from '../dayjs';
import { google } from 'calendar-link';
import startCase from 'lodash/startCase';
import { toRef, computed } from 'vue';

const getIcsUrl = computed(() => {
    const { state: { meeting } } = global;
    const { state: { payload } } = form;

    return window.base_url + 'meeting/' + meeting.area.slug + '/' + meeting.slug + '/' + payload.id + '/calendar';
});

const getGoogleUrl = computed(() => {
    const { state: { meeting } } = global;
    const { state: { payload } } = form;

    let start = dayjs(payload.date);
    let end = start.clone().add(meeting.duration, meeting.duration_unit.charAt(0));
    let policies = ``;
    if (meeting.updatable && meeting.update_policy_shown_in_external_calendar && meeting.update_policy) {
        policies += meeting.update_policy;
    }
    if (meeting.cancellable && meeting.cancellation_policy_shown_in_external_calendar && meeting.cancellation_policy) {
        policies += meeting.cancellation_policy;
    }
    const event = {
        title: `${meeting.area.name} - ${meeting.name}${getFormatedConsultant.value}`,
        description: `${meeting.area.name} - ${meeting.name}${getFormatedConsultant.value ? getFormatedConsultant.value : ''}<br><br>${meeting.description ? meeting.description : ''}<br><br>Date et heure: ${getFormatedDate.value}${policies}<br><br>Replanifier : <a href="${url.value + '/update'}">${url.value + '/update'}</a><br><br>Annuler : <a href="${url.value + '/cancel'}">${url.value + '/cancel'}</a>`,
        start: start.format(),
        end: end.format(),
    };

    return google(event);
});

const url = computed(() => {
    const { state: { meeting } } = global;
    const { state: { payload } } = form;

    return window.base_url + 'meeting/' + meeting.area.slug + '/' + meeting.slug + '/' + payload.id;
});

const getFormatedConsultant = computed(() => {
    const { state: { meeting } } = global;
    const { state: { payload } } = form;

    return meeting.consultant_selection_enabled && payload.consultant ? ` avec ${payload.consultant.last_name} ${payload.consultant.first_name}` : '';
});

const getFormatedDate = computed(() => {
    const { state: { payload } } = form;
    const { state: { meeting } } = global;
    const timezone = toRef(payload, 'timezone');

    let dateStart = dayjs(payload.date).tz(timezone.value);
    let dateEnd = dateStart.clone().add(meeting.duration, meeting.duration_unit.charAt(0));
    let dayStartFormat = startCase(dateStart.format('dddd D MMMM YYYY'));
    let hourStartFormat = dateStart.format('H:mm');
    let dayEndFormat = startCase(dateEnd.format('dddd D MMMM YYYY'));
    let hourEndFormat = dateEnd.format('H:mm');

    if (dateStart.isSame(dateEnd, 'day')) {
        return `<p>${dayStartFormat}, ${hourStartFormat} - ${hourEndFormat}</p>`;
    } else {
        return `<p>${dayStartFormat} - ${hourStartFormat} (début)</p><p>${dayEndFormat} - ${hourEndFormat} (fin)</p>`;
    }
});

export default {
    getIcsUrl,
    getGoogleUrl,
    url
};
