import { computed, ref, toRef } from 'vue';
import global from './global';
import Meeting from '../services/Meeting';
import popin from '../composables/popin';

const comment = ref('');
const selectedScore = ref(0);
const hoveredScore = ref(null);
const isLoading = ref(false);
const success = ref(false);

function setupSurvey() {
    const {
        state: {
            meeting,
        },
    } = global;

    const maxScore = toRef(meeting, 'satisfaction_survey_max_score');

    const stars = computed(() => {
        const array = [];
        for (let i = 1; i <= maxScore.value; i++) {
            array.push(i <= hoveredScore.value ? true : i <= selectedScore.value);
        }
        return array;
    });

    function onMouseEnter(score) {
        hoveredScore.value = score;
    }

    function onMouseLeave() {
        hoveredScore.value = null;
    }

    function selectScore(score) {
        if (score === selectedScore.value) {
            selectedScore.value = 0;
            hoveredScore.value = null;
        } else {
            selectedScore.value = score;
        }
    }

    return {
        stars,
        onMouseEnter,
        onMouseLeave,
        comment,
        selectScore,
    };

}

function sendSurvey() {
    const { state: { meeting, scheduledMeeting } } = global;
    const { showError, create, present } = popin;

    isLoading.value = true;
    if (selectedScore.value === 0) {
        isLoading.value = false;
        create({
            header: 'Attention',
            message: 'Veuillez sélectionner une note',
            confirmLabel: 'OK',
        });

        present();
        return;
    }
    Meeting.rateScheduledMeeting({
        data: {
            satisfaction_score: selectedScore.value,
            satisfaction_comment: comment.value,
        },
        meetingId: meeting.id,
        scheduledMeetingId: scheduledMeeting.id,
    }).subscribe(data => {
        success.value = true;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        isLoading.value = false;
    }, err => {
        showError(err);
        isLoading.value = false;
    });
}

export default {
    sendSurvey,
    setupSurvey,
    success,
    isLoading,
};
