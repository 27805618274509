<template>
    <div class="mt-4 position-relative" data-form-item>
        <input
            type="text"
            class="form-control"
            :class="{'is-invalid':errorMessage}"
            :id="field.id"
            :value="modelValue"
            :disabled="fieldLocked"
            :readonly="fieldLocked"
            @input="$emit('update:modelValue', $event.target.value)"
            aria-describedby="validationServer05Feedback"
            :placeholder="field.label"
        />
        <div class="invalid-tooltip">
            {{ errorMessage }}
        </div>
        <input-helper v-if="field.helper_text" v-html="field.helper_text"/>
    </div>
</template>

<script>
import { toRefs } from 'vue';
import isEmpty from 'lodash/isEmpty';
import InputHelper from '../input-helper.vue';

export default {
    components: { InputHelper },
    props: {
        field: Object,
        errorMessage: String,
        modelValue: String,
    },
    emits: ['update:modelValue'],
    setup(props) {
        const { field, modelValue } = toRefs(props);
        let fieldLocked = field.value.locked_when_prefilled
        if (isEmpty(modelValue.value) && fieldLocked) {
            fieldLocked = false
        }

        return {
            fieldLocked
        };
    },
};
</script>
