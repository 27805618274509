<template>
    <div class="panel p-md-5">
        <p class="typo-3" v-html="actionTitle"></p>
        <form @submit="onSubmit">
            <div class="bg-xs mb-3 mb-md-0">
                <div class="row gx-3">
                    <div class="col-md-6">
                        <col-summary></col-summary>
                    </div>
                    <div class="col-md-6">
                        <div class="scroll-wrap-confirmation mb-3">
                            <line-summary icon-name="pen-outline" v-if="penFields.length">
                                <template v-for="field in penFields">
                                    <p>{{ field }}</p>
                                </template>
                            </line-summary>

                            <line-summary icon-name="trombone-outline" v-if="fileFields.length">
                                <template v-for="field in fileFields">
                                    <p>{{ field.value.original_file_name }}</p>
                                </template>
                            </line-summary>
                            <AppExtraAttendees
                                v-if="meeting.allow_extra_attendees && 'create' === actionInProgress"
                            />
                        </div>
                        <app-field-checkbox
                            :field="field"
                            v-model="gdpr"
                            :errorMessage="errorMessage"
                            class="font-2 gray-color ls-2"
                        >
                            J’ai pris connaissance de la <a href="#" @click.prevent="gdprIsOpen = true">Politique de
                            confidentialité</a> et de la politique des cookies et je déclare en
                            accepter les termes pour ma demande de rendez-vous.
                        </app-field-checkbox>
                    </div>
                </div>
            </div>

            <div class="row">
                <legal-links class="order-2 order-md-1"></legal-links>
                <div class="col-md-6 d-flex justify-content-end order-1 order-md-2">
                    <button class="btn btn-primary w-mobile-100"
                            type="submit"
                            :id="'gtm-form-' + actionInProgress"
                            :disabled="isLoading">Confirmer
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { useField, useForm } from 'vee-validate';
import form from '../../composables/form';
import global from '../../composables/global';
import popin from '../../composables/popin';
import { computed, ref } from 'vue';
import filter from 'lodash/filter';
import map from 'lodash/map';
import AppExtraAttendees from '../app-extra-attendees.vue';
import dayjs from '../../dayjs';
import { upperFirst } from 'lodash';

export default {
    components: { AppExtraAttendees },
    setup() {
        const { state: { meeting } } = global;
        const { state: { payload: { dynamic_field_values } }, postScheduledMeeting, formatDataSourceValues } = form;
        const { actionTitle, state: { actionInProgress, config } } = global;
        const { gdprIsOpen, create: createPopin, present: presentPopin } = popin;
        const isQuitting = ref(false);
        const isLoading = ref(false);

        const field = {
            id: 'gdpr',
            label: ``,
        };

        const { handleSubmit } = useForm({
            initialValues: { gdpr: false },
        });
        const { errorMessage, value: gdpr } = useField('gdpr', { required: true });

        const onSubmit = handleSubmit(async values => {
            if (isQuitting.value) return;
            isQuitting.value = true;
            const { state: { meeting, actionInProgress } } = global;

            let okConfirm = true;
            if (meeting.cancelation_confirm_popin_enabled && actionInProgress === 'cancel') {
                createPopin({
                    header: 'Confirmation',
                    message: 'Êtes-vous sûr de vouloir annuler ce rendez-vous ?',
                    cancelLabel: 'Annuler',
                    confirmLabel: 'Oui',
                });
                okConfirm = await presentPopin();
            }
            if (okConfirm) {
                isLoading.value = true;
                postScheduledMeeting().subscribe(() => isLoading.value = false, () => isLoading.value = false);
            }
            isQuitting.value = false;
        });

        const penFields = computed(() => {
            const fields = filter(dynamic_field_values, (field) => {
                return field.value && !['mention', 'file', 'section_separator', 'hidden'].includes(field.dynamic_field.type);
            });
            return map(fields, (field) => {
                if (field.dynamic_field.type === 'checkbox') {
                    return field.dynamic_field.label;
                } else if (field.dynamic_field.type === 'datasource_multiselect') {
                    return field.value ? formatDataSourceValues(field.value) : '';
                } else if (field.dynamic_field.type === 'checkboxes') {
                    return field.value ? field.value.replace(/"/g, '').substring(1).slice(0, -1).replaceAll(',', ', ') : '';
                } else if (field.dynamic_field.type === 'date') {
                    return upperFirst(dayjs(field.value).format('dddd D MMMM YYYY'));
                } else {
                    return field.value;
                }
            });
        });

        const fileFields = computed(() => {
            return filter(dynamic_field_values, (field) => {
                return field.value && field.dynamic_field.type === 'file';
            });
        });

        return {
            field,
            onSubmit,
            errorMessage,
            gdpr,
            penFields,
            fileFields,
            actionTitle,
            isLoading,
            actionInProgress,
            gdprIsOpen,
            meeting,
        };
    },
};
</script>
