import '@babel/polyfill';
import { createApp } from 'vue';
import router from './router';
import Components from './components';
import VCalendar from './v-calendar';
import './axios';
import './vee-validate';
import { initSentry } from './sentry';

let app = createApp({});
app = Components(app);
app = VCalendar(app);
app.use(router);

initSentry(app);

router.isReady().then(() => app.mount('#app'));
