<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 25">
        <path clip-rule="evenodd" d="M12.39 1.667L15.725 5l-9.167 9.167H3.224v-3.333l9.167-9.167v0z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path stroke="currentColor" stroke-linecap="round" d="M1.409 23.667h17.125"/>
    </svg>
</template>

<script>
export default {};
</script>
