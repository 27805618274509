<template>
    <transition name="popin">
        <popin v-if="isOpen" @bgClick="onCancel">
            <p class="typo-1 text-center mb-7" v-if="header" v-html="header"></p>
            <div class="text-center font-4 mb-7" v-if="type === 'alert'">
                <p v-html="message"></p>
            </div>
            <div class="font-4 mb-7 popin__message" v-else v-html="message"></div>
            <div class="row justify-content-center">
                <div class="col-6" v-if="cancelLabel">
                    <button class="btn btn-tertiary w-100" @click="onCancel">{{
                            cancelLabel
                        }}
                    </button>
                </div>
                <div class="col-6" v-if="confirmLabel">
                    <button class="btn btn-primary w-100" @click="onConfirm">{{
                            confirmLabel
                        }}
                    </button>
                </div>
            </div>
        </popin>
    </transition>
</template>

<script>
import popin from '../../composables/popin';

export default {
    setup() {
        const {
            isOpen,
            type,
            header,
            message,
            cancelLabel,
            confirmLabel,
            onCancel,
            onConfirm,
        } = popin;

        return {
            isOpen,
            type,
            header,
            message,
            cancelLabel,
            confirmLabel,
            onCancel,
            onConfirm,
        };
    },
};
</script>
