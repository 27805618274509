<template>
    <div class="panel panel--infos p-md-5">
        <p class="typo-3 mb-md-0">Complétez les informations</p>
        <form @submit="onSubmit">
            <div class="scroll-wrap-form mb-3 position-relative" data-scroll-content>
                <div class="bg-xs">
                    <template v-for="(section, index) in computedFields" :key="index">
                        <div v-if="section.title" class="mt-5 py-2 mb-n4"
                             style="border-bottom: 1px solid var(--secondary-color)">
                            <span class="typo-2 text-secondary">{{ section.title }}</span>
                        </div>
                        <template v-for="(subsection, index) in section.fields" :key="index">
                            <div class="row gx-3">
                                <div class="col-12">
                                    <template v-for="(field, index) in subsection.fields" :key="index">
                                        <component
                                            v-if="field.display_column === 'full'"
                                            :is="'app-field-' + kebabCase(field.type)"
                                            :field="field"
                                            v-model="veeValues[field.id]"
                                            :errorMessage="errorMessages[field.id]"
                                        >
                                        </component>
                                    </template>
                                </div>
                                <div class="col-md-6">
                                    <template v-for="(field, index) in subsection.fields" :key="index">
                                        <component
                                            v-if="field.display_column === 'left'"
                                            :is="'app-field-' + kebabCase(field.type)"
                                            :field="field"
                                            v-model="veeValues[field.id]"
                                            :errorMessage="errorMessages[field.id]"
                                        >
                                        </component>
                                    </template>
                                </div>
                                <div class="col-md-6">
                                    <template v-for="(field, index) in subsection.fields" :key="index">
                                        <component
                                            v-if="field.display_column === 'right'"
                                            :is="'app-field-' + kebabCase(field.type)"
                                            :field="field"
                                            v-model="veeValues[field.id]"
                                            :errorMessage="errorMessages[field.id]"
                                        >
                                        </component>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="font-2 gray-color mt-3" v-if="!bpUpMd">- Les champs avec un * sont obligatoires</div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" v-if="bpUpMd">
                    <div class="position-relative">
                        <div class="form-mandatory font-2 gray-color">Les champs avec un * sont obligatoires</div>
                    </div>
                </div>
                <legal-links class="order-2 order-md-1"></legal-links>
                <div class="col-md-6 d-flex justify-content-end order-1 order-md-2">
                    <button class="btn btn-primary w-mobile-100" type="submit">Confirmer</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import form from '../../composables/form';
import responsive from '../../composables/responsive';
import { computed } from 'vue';
import forEach from 'lodash/forEach';
import { kebabCase } from 'lodash';

export default {
    setup() {
        const { initValidator, getFields: fields } = form;
        const { bpUpMd } = responsive;

        const {
            onSubmit,
            veeValues,
            errorMessages,
        } = initValidator();

        const getSections = (subFields) => {
            let section = 0;
            const array = [];

            forEach(subFields, field => {
                // test array[section] prevent first empty entry
                if (field.type === 'section_separator' && array[section]) {
                    section += 1;
                }
                if (!array[section]) array[section] = { fields: [] };
                if (field.type === 'section_separator') {
                    array[section].title = field.label;
                    array[section].type = 'section';
                } else {
                    array[section].fields.push(field);
                }
            });

            return array;
        };

        const getSubSections = (sections) => {
            forEach(sections, section => {
                let subSection = 0;
                const array = [];

                forEach(section.fields, field => {
                    // test array[subSection] prevent first empty entry
                    if (field.display_column === 'full' && array[subSection]) {
                        subSection += 1;
                    }
                    if (!array[subSection]) array[subSection] = { fields: [] };
                    array[subSection].fields.push(field);
                });
                section.fields = array;
            });

            return sections;
        };

        const computedFields = computed(() => {
            return getSubSections(getSections(fields.value));
        });

        return {
            fields,
            onSubmit,
            veeValues,
            errorMessages,
            bpUpMd,
            computedFields,
            kebabCase,
        };
    },
};
</script>
