<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 34">
        <path d="M31.707 16.105V17.5a15 15 0 11-8.895-13.71" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.207 4l-16.5 16.5-4.5-4.5" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {};
</script>
