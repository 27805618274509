import { computed, reactive, readonly, toRefs, onBeforeMount, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import forEach from 'lodash/forEach';
import kebabCase from 'lodash/kebabCase';

import steps from './steps';
import form from './form';
import responsive from './responsive';
import { upperFirst } from 'lodash';
import popin from './popin';
import Dayjs from '../dayjs';

const state = reactive({
    scheduledMeeting: {},
    meeting: {
        agency_selection_enabled: false,
        consultant_selection_enabled: false,
        hidden_agency_address: false,
        show_remaining_capacity_on_calendar: false,
    },
    config: {
        cookie_banner_content: null,
        gtm_id: null,
        privacy_policy: null,
    },
    hub: null,
    actionInProgress: 'create',
    isUpdatingDone: false,
    showLogo: true,
    isInPopin: false,
    gkey: '',
});

// getters
const actionTitle = computed(() => {
    return state.actionInProgress === 'cancel' ? 'Annulez le rendez-vous' : 'Confirmez le rendez-vous';
});

const isUpdatable = computed(() => {
    return state.meeting.updatable && state.scheduledMeeting.updatable;
});

const isCancellable = computed(() => {
    return state.meeting.cancellable && state.scheduledMeeting.cancellable;
});

const formatedDuration = computed(() => {
    const duration = Dayjs.duration(state.meeting.duration, state.meeting.duration_unit.substr(0, 1));
    const minutes = duration.get('minutes');
    const hours = duration.get('hours');
    const days = duration.get('days');

    return `${(days > 0 ? days + ' jour' + (days > 1 ? 's ' : ' ') : '')
    + (hours > 0 ? hours + ' heure' + (hours > 1 ? 's ' : ' ') : '')
    + (minutes > 0 ? minutes + ' minute' + (minutes > 1 ? 's' : '') : '')}`;
});

const meetingType = computed(() => {
    return {
        label: state.meeting.type === 'video' ? 'Visioconférence' : state.meeting.type === 'call' ? 'Par téléphone' : state.meeting.type === 'other' ? 'Autre' : upperFirst(state.meeting.area.label_agency),
        icon: state.meeting.type === 'video' ? 'camera' : state.meeting.type === 'call' ? 'phone' : state.meeting.type === 'other' ? 'other' : 'marker-outline',
    };
});

// Actions
function initApp(props) {
    const { meeting, config, scheduledMeeting, gkey, area, hub, limitedTimeSequences } = toRefs(props);
    const router = useRouter();
    const route = useRoute();
    const action = route.params.action;
    const { initSteps } = steps;
    const { initForm } = form;
    const { init: initResponsive, bpUpMd } = responsive;
    const { summaryIsOpen } = popin;

    onBeforeMount(() => {
        if (route.query.box) document.documentElement.classList.add('in-popin')
        state.showLogo = !(route.query.logo && route.query.logo === 'false');
        if (meeting.value) {
            state.meeting = meeting.value;
        } else {
            state.meeting.area = area.value;
        }
        state.hub = hub.value;
        state.config = config.value;
        state.gkey = gkey.value;
        state.limitedTimeSequences = limitedTimeSequences.value;
        if (scheduledMeeting.value) state.scheduledMeeting = scheduledMeeting.value;
        if (action) state.actionInProgress = action;
        initResponsive();
        initForm();
        initSteps();
        initTheme();
        if ((action === 'update' && !isUpdatable.value) ||
            action === 'cancel' && !isCancellable.value) {
            router.push({ name: 'cantUpdateCancel' });
        } else if (action === 'cancel') {
            updateActionInProgress('update');
            router.push({
                name: 'update-cancel',
                params: {
                    areaUuid: meeting.value.area.slug,
                    meetingId: meeting.value.slug,
                    scheduledMeetingId: scheduledMeeting.value.id,
                    action: 'update',
                },
            });
        }
    });

    onMounted(() => {
        if (!bpUpMd.value && state.actionInProgress === 'create' && route.name !== 'hub') summaryIsOpen.value = true;
    });
}

function updateScheduledMeeting(value) {
    state.scheduledMeeting = value;
}

function updateActionInProgress(value) {
    state.actionInProgress = value;
}

function updateUpdatingDone(value) {
    state.isUpdatingDone = value;
}

function initTheme() {
    const { meeting: { area, area: { theme } } } = state;

    forEach(area, function (value, key) {
        if (typeof value === 'string' && value.charAt(0) === '#' && value.length === 7) {
            document.documentElement.style.setProperty('--' + kebabCase(key), value);
            document.documentElement.style.setProperty('--' + kebabCase(key) + '--dark', LightenDarkenColor(value, -20));
            document.documentElement.style.setProperty('--' + kebabCase(key) + '--light', LightenDarkenColor(value, 20));
            document.documentElement.style.setProperty('--' + kebabCase(key) + '--contrast', invertColor(value, true));
        }
    });

    document.documentElement.classList.add('theme-' + theme);
    setBSThemeColor();
}

function LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    var b = ((num >> 8) & 0x00FF) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    var g = (num & 0x0000FF) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);

}

function invertColor(hex, bw) {

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
}

function setBSThemeColor() {
    const primaryColor = encodeURIComponent(getComputedStyle(document.documentElement).getPropertyValue('--primary-color'));
    // const secondaryColor = encodeURIComponent(getComputedStyle(document.documentElement).getPropertyValue('--secondary-color'));
    var style = document.createElement('style');
    style.innerHTML = `
        .was-validated .form-control:valid, .form-control.is-valid {
            background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 13'%3E %3Cpath stroke='${primaryColor}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15 1L4 12 1 9'/%3E %3C/svg%3E");
        }
        .form-select {
            background-image:url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 10'%3E %3Cpath d='M15.08 1.52L8.04 8.56 1 1.52' stroke='${primaryColor}' stroke-width='2' stroke-miterlimit='10'/%3E %3C/svg%3E");
        }
        .was-validated .form-select:invalid, .form-select.is-invalid{
            background-image:url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 10'%3E %3Cpath d='M15.08 1.52L8.04 8.56 1 1.52' stroke='%23dc3545' stroke-width='2' stroke-miterlimit='10'/%3E %3C/svg%3E"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        }
    `;
    document.head.appendChild(style);
}

function getMeetingUrl(action = 'update') {
    const { state: { payload } } = form;
    return window.base_url + 'meeting/' + state.meeting.area.slug + '/' + state.meeting.slug + '/' + payload.id + '/' + action;
}

export default {
    state: readonly(state),
    initApp,
    updateScheduledMeeting,
    updateActionInProgress,
    updateUpdatingDone,
    actionTitle,
    getMeetingUrl,
    isUpdatable,
    isCancellable,
    formatedDuration,
    meetingType,
};
