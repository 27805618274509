<template>
    <div class="row mb-5" @click="onLogoClick()">
        <div class="col d-flex align-items-center">
            <img
                :src="area.logo?.content_url"
                class="logo" v-if="showLogo" :alt="area.name"/>
            <p class="typo-1 mb-0"
               :class="{'ms-6' : showLogo}"
               v-if="meeting.welcome_text_app && bpUpMd && actionInProgress !== 'survey'"
               v-html="meeting.welcome_text_app"></p>
        </div>
    </div>
    <div class="row" v-if="actionInProgress !== 'survey'">
        <div class="col-md-7">
            <p class="typo-2 mb-3" v-if="name" v-html="name"></p>
        </div>
        <div class="col-md-5 my-2 my-md-0">
            <div class="row justify-content-md-end">
                <div class="col-auto d-flex">
                    <icon name="clock" class="me-3"></icon>
                    <span class="font-4">{{ duration }}</span>
                </div>
                <div class="col-auto d-flex" v-if="meetingType.icon !== 'other'">
                    <icon :name="meetingType.icon" class="me-3"></icon>
                    <span class="font-4" v-html="meetingType.label"></span>
                </div>
            </div>
        </div>
        <div v-if="description && bpUpMd"
             :class="{'col-md-7': description?.length < 210}"
             v-html="description"
             class="mb-3"></div>
    </div>
</template>

<script>
import global from '../composables/global';
import responsive from '../composables/responsive';
import popin from '../composables/popin';

export default {
    setup() {
        const {
            state: { meeting: { area, name, description }, meeting, showLogo, actionInProgress },
            formatedDuration: duration,
            meetingType,
        } = global;
        const { summaryIsOpen } = popin;
        const { bpUpMd } = responsive;

        function onLogoClick() {
            if (!bpUpMd.value) {
                summaryIsOpen.value = true;
            }
        }

        return {
            area,
            meeting,
            duration,
            meetingType,
            name,
            description,
            showLogo,
            bpUpMd,
            actionInProgress,
            onLogoClick,
        };
    },
};
</script>
