import { createRouter, createWebHistory } from 'vue-router';

import Main from './views/main.vue';
import CantUpdateCancel from './views/cant-update-cancel.vue';
import Hub from './views/hub.vue';

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: 'create',
            path: '/meeting/:areaUuid/:meetingId',
            component: Main,
        },
        {
            name: 'update-cancel',
            path: '/meeting/:areaUuid/:meetingId/:scheduledMeetingId/:action',
            component: Main,
        },
        {
            name: 'cantUpdateCancel',
            path: '/cant-update-cancel',
            component: CantUpdateCancel,
        },
        {
            name: 'hub',
            path: '/:areaUuid/meetings/:hubSlug',
            component: Hub,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
});
