// Views
import MyApp from './views/app.vue';

// Components
import TopHeader from './components/app-header.vue';
import Steps from './components/steps.vue';
import Summary from './components/summary.vue';
import ColSummary from './components/col-summary';
import LegalLinks from './components/legal-links';
import PanelAgency from './components/panels/panel-agency.vue';
import PanelConsultant from './components/panels/panel-consultant.vue';
import PanelCalendar from './components/panels/panel-calendar.vue';
import PanelInfos from './components/panels/panel-infos.vue';
import PanelConfirmation from './components/panels/panel-confirmation.vue';
import PanelSummary from './components/panels/panel-summary.vue';
import Popin from './components/popins/popin.vue';
import PopinAlert from './components/popins/popin-alert';
import PopinGdpr from './components/popins/popin-rgpd';
import PopinGdprForm from './components/popins/popin-rgpd-form';
import PopinSummary from './components/popins/popin-summary';
import SummaryWording from './components/summary-wording';
import Survey from './components/survey';

// Icons
import Icon from './components/app-icon.vue';
import CalendarOutline from './icons/calendar-outline.vue';
import Camera from './icons/camera.vue';
import CarretDown from './icons/carret-down.vue';
import Clock from './icons/clock.vue';
import Check from './icons/check.vue';
import Filter from './icons/filter.vue';
import MarkerOutline from './icons/marker-outline.vue';
import Marker from './icons/marker.vue';
import PenOutline from './icons/pen-outline.vue';
import Trash from './icons/trash.vue';
import TromboneOutline from './icons/trombone-outline.vue';
import User from './icons/user.vue';
import UserOutline from './icons/user-outline.vue';
import Validation from './icons/validation.vue';
import Phone from './icons/phone.vue';
import Reload from './icons/reload.vue';
import Star from './icons/star.vue';

import ApendayLogo from './icons/apenday.vue';

// Steps Icons
import StepUser from './icons/steps/step-user';
import StepMarker from './icons/steps/step-marker';
import StepCalendar from './icons/steps/step-calendar';
import StepPen from './icons/steps/step-pen';
import StepValidation from './icons/steps/step-validation';

// Form
import FieldText from './components/form/app-field-text';
import FieldTextarea from './components/form/app-field-textarea';
import FieldCheckbox from './components/form/app-field-checkbox';
import FieldCheckboxes from './components/form/app-field-checkboxes';
import FieldRadios from './components/form/app-field-radios';
import FieldSelect from './components/form/app-field-select';
import FieldMention from './components/form/app-field-mention';
import FieldFile from './components/form/app-field-file';
import FieldDate from './components/form/app-field-date';
import FieldDataSource from './components/form/app-field-datasource-multiselect.vue';
import FieldHidden from './components/form/app-field-hidden.vue';

export default function Components(app) {
    app.component('app-main', MyApp);
    app.component('top-header', TopHeader);
    app.component('steps', Steps);
    app.component('line-summary', Summary);
    app.component('col-summary', ColSummary);
    app.component('legal-links', LegalLinks);
    app.component('popin', Popin);
    app.component('popin-alert', PopinAlert);
    app.component('popin-gdpr', PopinGdpr);
    app.component('popin-gdpr-form', PopinGdprForm);
    app.component('popin-summary', PopinSummary);
    app.component('summary-wording', SummaryWording);
    app.component('survey', Survey);

    // Panels
    app.component('panel-agency', PanelAgency);
    app.component('panel-consultant', PanelConsultant);
    app.component('panel-calendar', PanelCalendar);
    app.component('panel-infos', PanelInfos);
    app.component('panel-confirmation', PanelConfirmation);
    app.component('panel-summary', PanelSummary);

    // Icons Managers
    app.component('icon', Icon);

    // Step Icons
    app.component('icon-step-consultant', StepUser);
    app.component('icon-step-agency', StepMarker);
    app.component('icon-step-calendar', StepCalendar);
    app.component('icon-step-infos', StepPen);
    app.component('icon-step-confirmation', StepValidation);

    // Icons
    app.component('icon-calendar-outline', CalendarOutline);
    app.component('icon-camera', Camera);
    app.component('icon-carret-down', CarretDown);
    app.component('icon-clock', Clock);
    app.component('icon-marker', Marker);
    app.component('icon-check', Check);
    app.component('icon-filter', Filter);
    app.component('icon-marker-outline', MarkerOutline);
    app.component('icon-pen-outline', PenOutline);
    app.component('icon-trash', Trash);
    app.component('icon-trombone-outline', TromboneOutline);
    app.component('icon-user', User);
    app.component('icon-user-outline', UserOutline);
    app.component('icon-validation', Validation);
    app.component('icon-phone', Phone);
    app.component('icon-reload', Reload);
    app.component('icon-star', Star);
    app.component('logo-apenday', ApendayLogo);

    // Form
    app.component('app-field-text', FieldText);
    app.component('app-field-email', FieldText);
    app.component('app-field-date', FieldDate);
    app.component('app-field-phone', FieldText);
    app.component('app-field-textarea', FieldTextarea);
    app.component('app-field-checkbox', FieldCheckbox);
    app.component('app-field-checkboxes', FieldCheckboxes);
    app.component('app-field-radios', FieldRadios);
    app.component('app-field-select', FieldSelect);
    app.component('app-field-mention', FieldMention);
    app.component('app-field-file', FieldFile);
    app.component('app-field-datasource-multiselect', FieldDataSource);
    app.component('app-field-hidden', FieldHidden);

    return app;
}
