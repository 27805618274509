<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22" class="icon icon-star">
        <path :fill="active ? 'currentColor' : 'transparent'" stroke="currentColor" stroke-width="2" d="M10.072 2.31c.336-.836 1.52-.836 1.856 0l1.942 4.836a1 1 0 00.86.625l5.198.352c.9.061 1.265 1.188.574 1.765l-3.999 3.341a1 1 0 00-.328 1.011l1.27 5.053c.22.874-.737 1.57-1.5 1.091l-4.413-2.77a1 1 0 00-1.064 0l-4.412 2.77c-.764.48-1.722-.217-1.502-1.09l1.271-5.054a1 1 0 00-.328-1.01L1.498 9.887c-.691-.578-.325-1.704.574-1.765l5.198-.352a1 1 0 00.86-.625l1.942-4.835z"/>
    </svg>
</template>

<script>
export default {
    props:['active']
};
</script>
