<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M2.667 5.333v8h8"/>
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6.013 20a12 12 0 102.84-12.48l-6.186 5.813"/>
    </svg>
</template>

<script>
export default {};
</script>
