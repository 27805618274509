import { ref, computed } from 'vue';
import throttle from 'lodash/throttle';

const breakpoint = ref(null);

function init() {
    refreshValue();

    let w = window.innerWidth;
    window.addEventListener('resize', () => {
        if (window.innerWidth === w) return;
        w = window.innerWidth;

        resizeEventThrottle();
    });
}

const resizeEventThrottle = throttle(function () {
    refreshValue();
}, 100);

function refreshValue() {
    breakpoint.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
}

const bpDownXs = computed(() => {
    return breakpointDown('xs');
});

const bpDownSm = computed(() => {
    return breakpointDown('sm');
});
const bpDownMd = computed(() => {
    return breakpointDown('md');
});

const bpDownLg = computed(() => {
    return breakpointDown('lg');
});

const bpDownXl = computed(() => {
    return breakpointDown('xl');
});

function breakpointDown(value) {
    switch (value) {
        case 'xs':
            return breakpoint.value === 'xs';

        case 'sm':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm');

        case 'md':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm' | breakpoint.value === 'md');

        case 'lg':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm' | breakpoint.value === 'md' | breakpoint.value === 'lg');

        case 'xl':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm' | breakpoint.value === 'md' | breakpoint.value === 'lg' | breakpoint.value === 'xl');

        case 'xxl':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm' | breakpoint.value === 'md' | breakpoint.value === 'lg' | breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        default:
            return false;
    }
}

const bpUpXs = computed(() => {
    return breakpointUp('xs');
});

const bpUpSm = computed(() => {
    return breakpointUp('sm');
});
const bpUpMd = computed(() => {
    return breakpointUp('md');
});

const bpUpLg = computed(() => {
    return breakpointUp('lg');
});

const bpUpXl = computed(() => {
    return breakpointUp('xl');
});

function breakpointUp(value) {
    switch (value) {
        case 'xs':
            return !!(breakpoint.value === 'xs' | breakpoint.value === 'sm' | breakpoint.value === 'md' | breakpoint.value === 'lg' | breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        case 'sm':
            return !!(breakpoint.value === 'sm' | breakpoint.value === 'md' | breakpoint.value === 'lg' | breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        case 'md':
            return !!(breakpoint.value === 'md' | breakpoint.value === 'lg' | breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        case 'lg':
            return !!(breakpoint.value === 'lg' | breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        case 'xl':
            return !!(breakpoint.value === 'xl' | breakpoint.value === 'xxl');

        case 'xxl':
            return breakpoint.value === 'xxl';

        default:
            return false;
    }
}

export default {
    init,
    breakpoint,
    bpDownXs,
    bpDownSm,
    bpDownMd,
    bpDownLg,
    bpDownXl,
    bpUpXs,
    bpUpSm,
    bpUpMd,
    bpUpLg,
    bpUpXl
};
