<template>
    <component :is="'icon-' + name" :class="'icon icon-' + name"></component>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        }
    }
};
</script>
