<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
        <path d="M2.667 5.167h15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17 5.167a1 1 0 10-2 0h2zm-11.667 0a1 1 0 10-2 0h2zm.5 0a1 1 0 002 0h-2zm6.667 0a1 1 0 002 0h-2zm2.5 0v11.667h2V5.167h-2zm0 11.667a.667.667 0 01-.667.667v2A2.667 2.667 0 0017 16.834h-2zm-.667.667H6v2h8.333v-2zM6 17.5a.667.667 0 01-.667-.667h-2A2.667 2.667 0 006 19.501v-2zm-.667-.667V5.167h-2v11.667h2zm2.5-11.667V3.501h-2v1.666h2zm0-1.666c0-.369.299-.667.667-.667v-2a2.667 2.667 0 00-2.667 2.667h2zm.667-.667h3.333v-2H8.5v2zm3.333 0c.369 0 .667.298.667.667h2A2.667 2.667 0 0011.833.834v2zm.667.667v1.666h2V3.501h-2z" fill="currentColor"/>
        <path d="M8.5 9.334v5M11.833 9.334v5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>

export default {};
</script>
