<template>
    <div class="mt-4 position-relative" :class="{'readonly': fieldLocked}" data-form-item>
        <div class="form-check-multiple position-relative" :class="{'is-invalid':errorMessage}">
            <p class="form-check-multiple__label">{{ field.label }}</p>
            <template v-for="(option, index) in field.options" :key="option">
                <div class="form-check form-check-inline mb-3">
                    <input class="form-check-input"
                           :class="{'is-invalid':errorMessage}"
                           type="checkbox"
                           :id="field.id + '_' + index"
                           :disabled="fieldLocked"
                           :value="option"
                           :checked="isChecked(option)"
                           @input="updateValue(option, $event.target.checked)"
                    >
                    <label class="form-check-label" :for="field.id + '_' + index">{{ option }}</label>
                </div>
            </template>
        </div>
        <div class="invalid-tooltip">
            {{ errorMessage }}
        </div>
        <input-helper v-if="field.helper_text" v-html="field.helper_text" />
    </div>
</template>

<script>
import { toRef, toRefs } from 'vue';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import InputHelper from '../input-helper.vue';

export default {
    components: { InputHelper },
    props: {
        field: Object,
        errorMessage: String,
        modelValue: Array,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { modelValue, field } = toRefs(props);

        let fieldLocked = field.value.locked_when_prefilled;
        if (isEmpty(modelValue.value) && fieldLocked) {
            fieldLocked = false;
        }

        const updateValue = function (option, checked) {
            let contextValue = clone(modelValue.value);
            if (contextValue === undefined) contextValue = [];
            if (checked) {
                const index = contextValue.indexOf(option);
                if (index === -1) {
                    contextValue.push(option);
                }
            } else {
                const index = contextValue.indexOf(option);
                if (index > -1) {
                    contextValue.splice(index, 1);
                }
            }

            emit('update:modelValue', contextValue);
        };

        const isChecked = function (option) {
            if (modelValue.value === undefined) return false;
            const index = modelValue.value.indexOf(option);
            return index > -1;
        };

        return { updateValue, isChecked, fieldLocked };
    },
};
</script>
