<template>
    <div data-form-item>
        <input
            type="hidden"
            :id="field.id"
            :value="modelValue"
        />
    </div>
</template>

<script>
export default {
    props: {
        field: Object,
        errorMessage: String,
        modelValue: String,
    },
    emits: ['update:modelValue'],
};
</script>
