<template>
    <div class="marker position-relative">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 27 22" width="28">
            <path fill="currentColor" fill-rule="evenodd" d="M23 9c0 7-9 13-9 13S5 16 5 9a9 9 0 0118 0z" clip-rule="evenodd"/>
        </svg>
<!--        <p class="marker__number">{{number}}</p>-->
    </div>
</template>

<script>
export default {
    props:['number']
};
</script>
