import Axios from '../axios';

let Gdpr = {
    anonymizeGDPR: function ({ data }) {
        return Axios.request({
            method: 'post',
            url: '/gdpr/anonymize',
            data,
        });
    },
};

export default Gdpr;
