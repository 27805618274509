<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17">
        <path d="M15 15.92v-1.75a3.5 3.5 0 00-3.5-3.5h-7a3.5 3.5 0 00-3.5 3.5v1.75" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path clip-rule="evenodd" d="M8 8a3.5 3.5 0 100-7 3.5 3.5 0 000 7z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {};
</script>
