<template>
    <template v-if="surveyEnabled && ratable">
        <div class="text-center w-100" v-if="!success">
            <p class="typo-4">Enquête de satisfaction</p>
            <p class="typo-2" v-html="questionLabel"></p>
            <div class="d-flex align-items-center justify-content-around my-5 stars-wrap">
                <template v-for="(star, index) in stars">
                    <icon-star
                        :active="star"
                        @mouseenter="onMouseEnter(index+1)"
                        @mouseleave="onMouseLeave(index+1)"
                        @click="selectScore(index+1)"
                    ></icon-star>
                </template>
            </div>
            <app-field-textarea
                v-if="allowComment"
                v-model="comment"
                :field="{id:'survey-comment', label:'Ajouter un commentaire ?'}"
            ></app-field-textarea>
        </div>
        <div v-else>
            <div class="d-flex justify-content-center h-100">
                <div class="text-center align-self-center">
                    <icon name="validation" class="mb-3"></icon>
                    <p class="confirm-msg">Merci ! Votre réponse est envoyée.</p>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="text-center d-flex align-items-center justify-content-center px-5">
            <p class="typo-4">Nous sommes désolés, vos réponses à notre enquête ont déjà été enregistrées ou le délai de réponse est dépassé.<br> Nous vous remercions.</p>
        </div>
    </template>
</template>
<script>
import global from '../composables/global';
import survey from '../composables/survey';

export default {
    setup() {
        const {
            state: {
                meeting: {
                    satisfaction_survey_question: questionLabel,
                    enabled_satisfaction_survey: surveyEnabled,
                    satisfaction_survey_allow_comment: allowComment,
                },
                scheduledMeeting: {
                    ratable,
                },
            },
        } = global;
        const { setupSurvey, success } = survey;
        const { stars, onMouseEnter, onMouseLeave, comment, selectScore } = setupSurvey();

        return {
            stars,
            onMouseEnter,
            onMouseLeave,
            selectScore,
            comment,
            questionLabel,
            surveyEnabled,
            allowComment,
            success,
            ratable
        };
    },
};
</script>
