<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
        <path d="M21 11.07V12a10 10 0 11-5.93-9.14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22 3L11 14l-3-3" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {};
</script>
