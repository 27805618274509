<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18">
        <path clip-rule="evenodd" d="M17.037 7.667c0 5.185-6.667 9.63-6.667 9.63s-6.666-4.445-6.666-9.63a6.667 6.667 0 0113.333 0v0z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path clip-rule="evenodd" d="M10.37 9.889a2.222 2.222 0 100-4.445 2.222 2.222 0 000 4.445z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {};
</script>
