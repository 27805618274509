<template>
    <div class="mt-4 position-relative" data-form-item>
        <select class="form-select form-select--hub"
                v-if="bpDownSm"
                :class="{empty:!modelValue, 'is-invalid':errorMessage}"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :aria-label="field.label"
        >
            <option value="" selected>{{ field.label }}</option>
            <option
                v-for="(option, index) in field.options"
                :value="index">{{ option.label }}
            </option>
        </select>

        <div class="form-select form-select--hub" :class="{empty:!(modelValue !== null), 'is-invalid':errorMessage}"
             v-else
             @click="onFieldClick()" ref="customSelectRef">
            <span>{{ selectedLabel }}</span>
        </div>
        <transition name="custom-select">
            <div class="hub-select__wrapper" v-if="isOpen"
                 :style="'height: ' + height + 'px'">
                <div class="hub-select__options">
                    <div class="hub-select__option hub-select__option--default"
                         v-if="!field.required"
                         @click="onSelect(null)">Sans préférences
                    </div>
                    <div class="hub-select__option"
                         v-for="(option, index) in field.options"
                         @click="onSelect(index)">
                        {{ option.label }}
                    </div>
                </div>
            </div>
        </transition>
        <div class="invalid-tooltip">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
import { computed, toRefs, ref, onMounted, onUnmounted, watch } from 'vue';
import responsive from '../../composables/responsive';

export default {
    props: {
        field: Object,
        errorMessage: String,
        modelValue: [Number, Boolean],
        height: Number,
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { height, modelValue, field } = toRefs(props);
        const { bpDownSm } = responsive;
        const isOpen = ref(false);
        const customSelectRef = ref(null);

        const selectedLabel = computed(() => {
            return modelValue.value !== null ? field.value.options[modelValue.value].label : field.value.label;
        });

        function onFieldClick() {
            isOpen.value = !isOpen.value;
        }

        function onSelect(index) {
            emit('update:modelValue', index);
            isOpen.value = false;
        }

        onMounted(() => {
            document.body.addEventListener('click', clickOutsideEvent);
        });

        onUnmounted(() => {
            document.body.removeEventListener('click', clickOutsideEvent);
        });

        function clickOutsideEvent(event) {
            if (!customSelectRef.value) return;
            if (!(customSelectRef.value === event.target || customSelectRef.value.contains(event.target))) {
                isOpen.value = false;
            }
        }

        return {
            selectedLabel,
            isOpen,
            onFieldClick,
            customSelectRef,
            onSelect,
            height,
            bpDownSm,
        };
    },
};
</script>
