<template>
    <div class="steps-wrapper">
        <template v-for="step in steps">
            <div class="step-icon"
                 :class="{ active: step.status === 1, done: step.status === 2, disabled: getActiveStep.slug === 'summary' }"
                 :data-tooltip="step.label"
                 :data-cy-step="step.slug"
                 @click="goToStep(step)"
                 v-if="step.slug !== 'summary'">
                <div class="step-icon__bg"></div>
                <div class="step-icon__mask">
                    <component :is="'icon-step-'+step.slug"
                               :class="'step-icon__icon ' + 'icon-step-'+step.slug"></component>
                    <div class="step-icon__bar"></div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import stepsStore from '../composables/steps';

export default {
    setup() {
        const { setActiveStep, getActiveStep, getSteps: steps } = stepsStore;
        const goToStep = function (step) {
            if (step.status === 2 && getActiveStep.value.slug !== 'summary') {
                setActiveStep(step.slug);
            }
        };

        return {
            steps,
            goToStep,
            getActiveStep,
        };
    },
};
</script>
