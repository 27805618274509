<template>
    <div class="popin">
        <div class="popin_bg" @click.self="onBgClick"></div>
        <div class="popin_content">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    emits: ['bgClick'],
    setup(_, { emit }) {

        function onBgClick() {
            emit('bgClick');
        }

        return {
            onBgClick,
        };
    },
};
</script>
