<template>
    <div class="col-md-6 col--legal-links d-flex height-btn my-4 my-md-0 ">
        <a href="#" class="list-inline-separator" @click.prevent="gdprIsOpen = true" data-cy="gdpr__privacy_policy">Politique de confidentialité</a>
    </div>
</template>

<script>
import popin from '../composables/popin';
import global from '../composables/global';

export default {
    setup() {
        const { gdprIsOpen } = popin;
        const { state: { config } } = global;

        return {
            gdprIsOpen,
        };
    },
};
</script>
