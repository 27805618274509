<template>
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1481 30.0001V26.4816C28.1481 22.5952 24.9976 19.4446 21.1111 19.4446H7.03704C3.15059 19.4446 0 22.5952 0 26.4816V30.0001" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0741 14.0741C17.9606 14.0741 21.1112 10.9235 21.1112 7.03704C21.1112 3.15059 17.9606 0 14.0741 0C10.1877 0 7.03711 3.15059 7.03711 7.03704C7.03711 10.9235 10.1877 14.0741 14.0741 14.0741Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {};
</script>
