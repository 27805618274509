import { reactive, computed, readonly, toRef, ref, watchEffect, nextTick } from 'vue';
import global from './global';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import { useField, useForm } from 'vee-validate';
import steps from './steps';
import Meeting from '../services/Meeting';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import calendar from './calendar';
import dayjs from '../dayjs';
import popin from './popin';
import { throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

const state = reactive({
    isLoading: false,
    payload: {
        date: '',
        dynamic_field_values: [],
        locks: [],
    },
});

let showFormCount = 0;

// Getters
const getFields = computed(() => {
    const { state: { actionInProgress, meeting } } = global;
    const dynamic_fields = toRef(meeting, 'dynamic_fields');
    const fields = cloneDeep(filter(dynamic_fields.value, (field) => {
        return field.shown_at_inital_process && actionInProgress === 'create' ||
            field.shown_at_update_process && actionInProgress === 'update' ||
            field.shown_at_cancellation_process && actionInProgress === 'cancel';
    }));
    return map(fields, (field) => {
        if (field.required && field.type !== 'section_separator') {
            field.label = field.label + ' *';
        }
        if (['section_separator', 'hidden', 'mention'].includes(field.type)) {
            field.required = false;
        }
        return field;
    });
});

const hasUpdateFields = computed(() => {
    const { state: { meeting } } = global;
    const dynamic_fields = toRef(meeting, 'dynamic_fields');

    const fields = filter(dynamic_fields.value, (field) => {
        return field.shown_at_update_process;
    });

    return !!fields.length;
});

const hasCancelFields = computed(() => {
    const { state: { meeting } } = global;
    const dynamic_fields = toRef(meeting, 'dynamic_fields');

    const fields = filter(dynamic_fields.value, (field) => {
        return field.shown_at_cancellation_process;
    });

    return !!fields.length;
});

const getInitialValues = computed(() => {
    const initialValues = {};
    forEach(state.payload.dynamic_field_values, (field) => {
        let value;
        if (field.dynamic_field?.type === 'file') {
            value = field.attachment ?? '';
        } else {
            value = field.dynamic_field?.type === 'checkboxes' && field.value ? JSON.parse(field.value) : field.value;
        }
        initialValues[field.dynamic_field?.id] = value;
    });
    return initialValues;
});

// Actions
function updateDate(value) {
    state.payload.date = value;
}

function updateFormAgency(agency) {
    if (!agency ||
        (agency && state.payload.agency && state.payload.agency.id === agency.id)) {
        state.payload.agency = null;
    } else {
        state.payload.agency = agency;
    }
}

function lockAgency() {
    state.payload.locked_agency = true;
}

function isLockedAgency() {
    const { state: { scheduledMeeting } } = global;
    const lockedAgency = toRef(scheduledMeeting, 'locked_agency');

    return state.payload.locked_agency || lockedAgency.value;
}

function isLockedConsultant() {
    const { state: { scheduledMeeting } } = global;
    const lockedConsultants = toRef(scheduledMeeting, 'locked_consultants');

    return state.payload.locked_consultants || lockedConsultants.value;
}

function isLockedDate() {
    const { state: { scheduledMeeting } } = global;
    const lockedDate = toRef(scheduledMeeting, 'locked_date');

    return state.payload.locked_date || lockedDate.value;
}

function updateFormConsultant(consultant) {
    if (!consultant ||
        (consultant && state.payload.consultant && state.payload.consultant.id === consultant.id)) {
        state.payload.consultant = null;
    } else {
        state.payload.consultant = consultant;
    }
}

function updateFormPostalCode() {
    const postalCode = localStorage.getItem('apendayPostalCode');
    if (null !== postalCode) {
        state.payload.postal_code = postalCode;
    }
}

function updateFormLocks(locks) {
    state.payload.locks = locks;
}

function initForm() {
    const { state: { meeting: { area: { default_timezone } } }, state: stateGlobal } = global;
    const scheduledMeeting = toRef(stateGlobal, 'scheduledMeeting');
    const { updateActiveDay } = calendar;
    // TODO check if consultant and agency exist on meeting options
    state.payload.timezone = default_timezone;

    if (!isEmpty(scheduledMeeting.value)) {
        state.payload = cloneDeep(scheduledMeeting.value); // timezone updated by scheduledMeeting
        updateActiveDay(dayjs(state.payload.date).format('YYYY-MM-DD'));
    }
}

function initValidator() {
    const { goNextStep } = steps;

    const { handleSubmit, meta: formMeta, setTouched } = useForm({
        initialValues: cloneDeep(getInitialValues.value),
    });

    const errorMessages = reactive({});
    const _errorMessages = computed(() => {
        return formMeta.value.touched ? errorMessages : {};
    });
    const veeValues = reactive({});
    const validationRules = reactive({});
    const isQuitting = ref(false);

    getFields.value.forEach((field) => {
        validationRules[field.id] = getValidationRules(field);
        const rules = ref(validationRules[field.id]);
        const { errorMessage, value } = useField(field.id, rules, {
            label: field.type,
        });
        veeValues[field.id] = value;
        errorMessages[field.id] = errorMessage;
        watchEffect(() => {
            if (!validationRules[field.id]['required'] && isEmpty(value.value)) {
                nextTick(() => {
                    rules.value = '';
                });
            } else {
                nextTick(() => {
                    rules.value = validationRules[field.id];
                });
            }
        });
    });

    const onSubmit = handleSubmit((values) => {
        if (isQuitting.value) return;
        isQuitting.value = true;
        const { state: { meeting } } = global;
        const dynamic_fields = toRef(meeting, 'dynamic_fields');

        forEach(values, (value, key) => {
            const index = findIndex(state.payload.dynamic_field_values, (field) => field.dynamic_field && field.dynamic_field.id === key);
            if (index !== -1) {
                // field exist, only update
                state.payload.dynamic_field_values[index].value = Array.isArray(value) ? JSON.stringify(value) : value;
            } else {
                // field don't exist, create
                state.payload.dynamic_field_values.push({
                    'dynamic_field': find(dynamic_fields.value, field => field.id === key),
                    'value': Array.isArray(value) ? JSON.stringify(value) : value,
                });
            }
        });
        goNextStep();
    }, ({ errors }) => {
        const scrollContent = document.querySelector('[data-scroll-content]');
        const id = Object.keys(errors)?.[0];

        if (!scrollContent || !id) {
            return;
        }
        const invalidField = document.getElementById(id);

        if (invalidField) {
            const top = invalidField.closest('[data-form-item]')?.offsetTop - 15;
            scrollContent.scroll({
                top: top > 0 ? top : 0,
                behavior: 'smooth',
            });
        }
    });

    // Auto submit if form is filled
    const autoSubmit = async () => {
        if (showFormCount === 0) { // Prevent auto submit when user go back on the form page
            // check if minimum one field is filled
            const filledField = find(veeValues, o => o);
            if (filledField) {
                await onSubmit();
                if (!formMeta.value.valid) setTouched(false);
            }
            showFormCount++;
        }
    };

    autoSubmit();

    return {
        onSubmit,
        veeValues,
        errorMessages: _errorMessages,
    };
}

function postScheduledMeeting(forcedAction) {
    const { state: { actionInProgress, meeting }, updateScheduledMeeting, updateUpdatingDone } = global;
    const { goNextStep } = steps;
    const { showError } = popin;

    let action = (forcedAction ?? actionInProgress) + 'ScheduledMeeting';

    return Meeting[action]({
        data: sanitizePayload(cloneDeep(state.payload), meeting.area),
        meetingId: meeting.id,
        scheduledMeetingId: state.payload.id ? state.payload.id : null,
    }).pipe(
        tap((scheduledMeeting) => {
            updateScheduledMeeting(scheduledMeeting);
            state.payload = scheduledMeeting;
            updateUpdatingDone(true);
            goNextStep();
        }),
        catchError(err => {
            showError(err);
            return throwError(err);
        }),
    );
}

function getValidationRules(item) {
    let validation = {};
    if (item.type === 'mention') return validation;
    if (item.required) validation.required = true;
    if (item.type === 'file') return validation;
    if (item.type === 'email') validation.email = true;

    if (item.constraints.length) {
        item.constraints.forEach((constraint) => {
            validation[constraint.type] = constraint.value ?? true;
        });
    }

    return validation;
}

function sanitizePayload(payload, area) {
    const { state: { meeting, actionInProgress } } = global;
    delete payload.consultants;
    if (!meeting.consultant_selection_enabled || !payload.consultant) {
        delete payload.consultant;
    }
    if (!meeting.agency_selection_enabled || !payload.agency) {
        delete payload.agency;
    }

    payload = omit(payload, ['id', 'cancellable', 'updatable', 'status']);
    payload.dynamic_field_values = filter(payload.dynamic_field_values, ({ dynamic_field }) => {
        return dynamic_field.shown_at_inital_process && actionInProgress === 'create' ||
            dynamic_field.shown_at_update_process && actionInProgress === 'update' ||
            dynamic_field.shown_at_cancellation_process && actionInProgress === 'cancel';
    });
    payload.dynamic_field_values = filter(payload.dynamic_field_values, field => field.value !== undefined && field.value !== null);

    // Clean fields values
    payload.dynamic_field_values = map(payload.dynamic_field_values, (dynamic_field_value) => {
        let value;
        if (dynamic_field_value.dynamic_field.type === 'file') {
            value = dynamic_field_value.value.id ?? dynamic_field_value.value;
        } else if (dynamic_field_value.dynamic_field.type === 'checkbox') {
            value = dynamic_field_value.value.toString();
        } else {
            value = Array.isArray(dynamic_field_value.value) ? JSON.stringify(dynamic_field_value.value) : dynamic_field_value.value;
        }

        return {
            dynamic_field: { id: dynamic_field_value.dynamic_field.id },
            value,
        };
    });

    payload.timezone = payload.timezone || area.default_timezone;

    return mapValues(payload, function (value, key) {
        if (key === 'consultant' || key === 'agency') {
            return { id: value.id };
        }
        return value;
    });
}

function setTimezone() {
    state.payload.timezone = state.payload.timezone === 'Europe/Paris' ? 'America/Toronto' : 'Europe/Paris';
}

function updateExtraAttendees(attendees) {
    state.payload.extra_attendees = filter(attendees, (attendee) => attendee.email !== '');
}

function formatDataSourceValues(values) {
    const _values = JSON.parse(values);
    if (_values.hasOwnProperty('__other__')) {
        return _values['__other__'];
    }
    const lastValue = getLastValue(_values);

    return lastValue['label'];
}

function getLastValue(values) {
    let keyOfHighestLevel = 0;
    let testLevel = 0;

    forEach(values, (val, key) => {
        if (val?.level > testLevel) {
            testLevel = val.level;
            keyOfHighestLevel = key;
        }
    });

    return values[keyOfHighestLevel];
}

export default {
    state: readonly(state),
    updateFormAgency,
    lockAgency,
    isLockedAgency,
    isLockedConsultant,
    isLockedDate,
    updateFormConsultant,
    updateFormPostalCode,
    updateDate,
    updateFormLocks,
    initForm,
    initValidator,
    getFields,
    postScheduledMeeting,
    setTimezone,
    hasUpdateFields,
    hasCancelFields,
    updateExtraAttendees,
    formatDataSourceValues,
    getLastValue,
};
