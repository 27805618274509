<template>
    <div class="scroll-wrap-form">
        <line-summary icon-name="marker-outline" v-if="agency_selection_enabled && agency">
            <p class="typo-2" v-if="agency.name" data-cy="summary_agency_name">{{ agency.name }}</p>
            <p class="font-4" v-if="agency.address && !hidden_agency_address">{{ agency.address }}</p>
            <p class="font-4" v-if="agency.postal_code && !hidden_agency_address">{{ agency.postal_code }}</p>
            <p class="font-4" v-if="agency.city && !hidden_agency_address">{{ agency.city }}</p>
            <p class="font-4" v-if="agency.phone_number">{{ agency.phone_number }}</p>
        </line-summary>
        <line-summary icon-name="user-outline"
                      v-if="consultant_selection_enabled && consultant && actionInProgress !== 'survey'">
            <p class="typo-2" data-cy="summary_consultant_name">{{ consultant.first_name }} {{
                    consultant.last_name
                }}</p>
            <template v-if="consultant.function">
                <p class="font-4">{{ consultant.function.name }}</p>
            </template>
        </line-summary>
        <line-summary icon-name="calendar-outline">
            <p class="typo-2 text-capitalize" data-cy="summary_date">{{ date }}</p>
            <p class="font-4" v-html="sequence"></p>
        </line-summary>
    </div>
</template>

<script>
import global from '../composables/global';
import consultantStore from '../composables/consultant';
import calendar from '../composables/calendar';
import { toRef } from 'vue';

export default {
    setup() {
        const { getAgency: agency, getConsultant: consultant } = consultantStore;
        const {
            state: {
                meeting: { agency_selection_enabled, consultant_selection_enabled, hidden_agency_address },
                actionInProgress: action,
            },
        } = global;
        const { getFormatedDate: date, getFormatedSequence: sequence } = calendar;
        const actionInProgress = toRef(action);

        return {
            agency,
            consultant,
            agency_selection_enabled,
            consultant_selection_enabled,
            hidden_agency_address,
            date,
            sequence,
            actionInProgress,
        };
    },
};
</script>
