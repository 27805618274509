<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
        <path clip-rule="evenodd" d="M3.193 5.476a2 2 0 012-2h11a2 2 0 012 2v11a2 2 0 01-2 2h-11a2 2 0 01-2-2v-11z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.026 1.81v3.333M7.36 1.81v3.333M3.193 8.476h15" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {};
</script>
